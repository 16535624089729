import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { parse as parseCR } from 'content-range';

import {
    CHANGE_PAGE,
    ChangePageAction,
    GET_MY_OFFERS,
    GET_MY_OFFERS_SUCCESS,
    GET_MY_OFFERS_ERROR,
    GetMyOffersAction,
    GetMyOffersSuccessAction,
    GetMyOffersErrorAction,
    ANSWER_PROPOSAL,
    ANSWER_PROPOSAL_SUCCESS,
    ANSWER_PROPOSAL_ERROR,
    AnswerProposalAction,
    AnswerProposalSuccessAction,
    AnswerProposalErrorAction,
    COMPLETE_OFFER,
    COMPLETE_OFFER_SUCCESS,
    COMPLETE_OFFER_ERROR,
    CompleteOfferAction,
    CompleteOfferSuccessAction,
    CompleteOfferErrorAction,
} from './types';
import { OfferData } from '../offer/types';

export const changePage = (page: number, type: string): ChangePageAction => ({
    type: CHANGE_PAGE,
    payload: {
        page,
        type,
    },
});

export const getMyOffers = (): GetMyOffersAction => ({
    type: GET_MY_OFFERS,
});

export const getMyOffersSuccess = (res: AjaxResponse<OfferData[]>, type: string): GetMyOffersSuccessAction => ({
    type: GET_MY_OFFERS_SUCCESS,
    payload: {
        offers: res.response,
        type,
        size: parseCR(res.xhr.getResponseHeader('content-range') || '')?.size || 0,
    },
});

export const getMyOffersError = (error: AjaxError): GetMyOffersErrorAction => ({
    type: GET_MY_OFFERS_ERROR,
    error,
});

export const answerProposal = (id: string, reason: string, decision: string): AnswerProposalAction => {
    const payload = {
        id,
        reason,
        decision,
    };

    if (reason) {
        payload.reason = reason;
    }

    return {
        type: ANSWER_PROPOSAL,
        payload,
    };
};

export const answerProposalSuccess = (): AnswerProposalSuccessAction => ({
    type: ANSWER_PROPOSAL_SUCCESS,
});

export const answerProposalError = (error: AjaxError): AnswerProposalErrorAction => ({
    type: ANSWER_PROPOSAL_ERROR,
    error,
});

export const completeOffer = (id: string): CompleteOfferAction => ({
    type: COMPLETE_OFFER,
    payload: {
        id,
    },
});

export const completeOfferSuccess = (): CompleteOfferSuccessAction => ({
    type: COMPLETE_OFFER_SUCCESS,
});

export const completeOfferError = (error: AjaxError): CompleteOfferErrorAction => ({
    type: COMPLETE_OFFER_ERROR,
    error,
});
