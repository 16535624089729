import React, { FC, useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    TextField,
    Icon,
    IconButton,
    FormControlLabel,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Tooltip,
    SelectChangeEvent,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import OfferGrid from '../../components/offer-grid';
// "as uSavedOrder" because "useSavedOrderId" is inferred to be a hook
import {
    makeNewOffer,
    getSavedOrder,
    useSavedOrderId as uSavedOrderId,
    autoSave,
    saveFile,
    removeFile,
    submitOffer,
    getCustomers,
} from '../../redux/offer/actions';
import { getConfig } from '../../redux/settings/actions';

import { OGCellType, OGColumnDef, IFile, OfferData } from '../../redux/offer/types';
import { RootState } from '../../redux';
import OfferInfoModal from '../../components/offer-info-modal';
import { Role } from '../../redux/user/types';
import { useNavigate } from 'react-router-dom';

import LoadSavedOrderModal from './LoadSavedOrderModal';

const PREFIX = 'Offer';

const classes = {
    inputLong: `${PREFIX}-inputLong`,
    select: `${PREFIX}-select`,
    offerText: `${PREFIX}-offerText`,
    mandatoryText: `${PREFIX}-mandatoryText`,
    dl_container: `${PREFIX}-dl_container`,
    textField: `${PREFIX}-textField`,
    fileList: `${PREFIX}-fileList`,
    fileListText: `${PREFIX}-fileListText`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.inputLong}`]: {
        width: 350,
    },

    [`& .${classes.select}`]: {
        width: 350,
    },

    [`& .${classes.offerText}`]: {
        maxWidth: '100%',
        width: '800px',
        margin: 'auto',
        fontSize: theme.typography.fontSize,
    },

    [`& .${classes.mandatoryText}`]: {
        // display: 'inline-block',
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
        paddingBottom: '2px',
    },

    [`& .${classes.dl_container}`]: {
        display: 'inline-block',
    },

    [`& .${classes.textField}`]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },

    [`& .${classes.fileList}`]: {
        minWidth: '300px',
        display: 'inline-block',
    },

    [`& .${classes.fileListText}`]: {
        paddingRight: theme.spacing(2),
    },
}));

interface FormSimpleData {
    customerId: string;
    newCustomerName: string;
    reference: string;
    message: string;
    fast: boolean;
    deadlineWish: string | undefined;
    gridHeader: OGColumnDef[];
    gridData: OGCellType[];
}

const Offer: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ON MOUNT
    useEffect(() => {
        dispatch(getConfig());
        dispatch(getCustomers());

        document.title = 'Tarjouspyyntöportaali';

        // Clear autosave timeout on unmount
        return () => {
            if (blurTimeout) {
                clearTimeout(blurTimeout);
            }
        };
    }, []);

    // REDUX //

    // Offer
    const { customers, savedOrders, fileIdReplace, id: offerId } = useSelector((state: RootState) => state.offer);
    let { newCustomerId } = useSelector((state: RootState) => state.offer);

    // Settings
    const { deadlineWishDefaultAfterDays, offerRequestInfo } = useSelector(
        (state: RootState) => state.settings.simpleData,
    );

    // User
    const { id: myId, role: myRole } = useSelector((state: RootState) => state.user);

    // STATE //

    // Confirmation modal
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // Grid data state (not including files)
    const emptyFormSimpleData = {
        customerId: '',
        newCustomerName: '',
        reference: '',
        message: '',
        fast: false,
        deadlineWish: new Date(new Date().getTime() + (deadlineWishDefaultAfterDays ?? 7) * 86400000)
            .toISOString()
            .split('T')[0],
        gridData: [],
        gridHeader: [],
    };
    const [formSimpleData, setFormSimpleData] = useState<FormSimpleData>(emptyFormSimpleData);

    // Use this to make sure values from API (like settings) are updated properly once they get fetched
    useEffect(() => {
        setFormSimpleData(emptyFormSimpleData);
    }, [deadlineWishDefaultAfterDays]);

    // This is required for timeouts to use the newest version of formSimpleData
    const formSimpleDataRef = useRef(formSimpleData);
    formSimpleDataRef.current = formSimpleData;
    // Data of saved order to be loaded
    const [loadedData, setLoadedData] = useState<(string | null)[][] | null>(null);
    // Autosave timeout
    const [blurTimeout, setBlurTimeout] = useState<NodeJS.Timeout | null>(null);

    // File state
    const [formFiles, setFormFiles] = useState<IFile[]>([]);

    // Customer selection
    useEffect(() => {
        if (customers.length > 0) {
            const me = customers.find((c) => c.user === myId);
            if (me) {
                setFormSimpleData({ ...formSimpleData, customerId: me.id || '' });
            } else {
                setFormSimpleData({ ...formSimpleData, customerId: customers[0].id || '' });
            }
        }
    }, [customers]);

    // Replace file tmpIds with the ids from the API
    useEffect(() => {
        if (fileIdReplace) {
            for (let i = 0; i < formFiles.length; i++) {
                if (formFiles[i].id === fileIdReplace.tmpId) {
                    formFiles[i].id = fileIdReplace.id;
                }
            }
            setFormFiles(formFiles);
        }
        // fileIdReplace is the important one here
    }, [fileIdReplace, formFiles, dispatch]);

    // On first enter, check if saved orders
    useEffect(() => {
        if (!offerId) {
            dispatch(getSavedOrder());
        }
    }, [offerId, dispatch]);

    // If saved offer, offer to load it up or automatically load if empty
    // Else, create new offer
    const [showLoadConfirmationModal, setShowLoadConfirmationModal] = useState(false);
    useEffect(() => {
        if (savedOrders && savedOrders.length > 0) {
            setShowLoadConfirmationModal(true);
        } else if (savedOrders) {
            dispatch(makeNewOffer());
        }
    }, [savedOrders, dispatch]);

    const [selectedOfferToLoad, setSelectedOfferToLoad] = useState('');

    // Loading a saved order after "yes" in modal
    const loadSavedOrder = (id: string) => {
        // To get here savedOrders must have at least on entry, but typescript doesn't know it
        if (savedOrders && savedOrders.length > 0 && id) {
            const savedOrder = savedOrders?.find((o) => o.id === id);

            if (savedOrder) {
                // Load values
                setFormSimpleData({
                    ...formSimpleData,
                    customerId: savedOrder.customer?.id || '',
                    newCustomerName: savedOrder.customer?.customUser ? savedOrder.customer?.name || '' : '',
                    reference: savedOrder.reference || '',
                    message: savedOrder.message || '',
                    fast: savedOrder.fast || false,
                    deadlineWish:
                        savedOrder.deadlineWish && new Date() < new Date(savedOrder.deadlineWish)
                            ? savedOrder.deadlineWish
                            : new Date(new Date().getTime() + (deadlineWishDefaultAfterDays ?? 7) * 86400000)
                                  .toISOString()
                                  .split('T')[0],
                });

                // Files
                if (savedOrder.files) {
                    const newFiles: IFile[] = [];
                    for (let i = 0; i < savedOrder.files.length; i++) {
                        newFiles.push({
                            id: savedOrder.files[i].id,
                            name: savedOrder.files[i].name,
                            active: savedOrder.files[i].active,
                        });
                    }
                    setFormFiles(newFiles);
                }

                // Grid value
                const loadedData: (string | null)[][] = [];
                if (savedOrder.offerTable) {
                    for (let i = 0; i < savedOrder.offerTable.rows.length; i++) {
                        const row: (string | null)[] = [];
                        for (let j = 0; j < savedOrder.offerTable.rows[0].cells.length; j++) {
                            row.push(savedOrder.offerTable.rows[i].cells[j].content);
                        }
                        loadedData.push(row);
                    }
                }

                setLoadedData(loadedData);
            }
        }
    };

    // HANDLERS AND HELPERS //

    // Load confirmation modal helpers
    const handleLoadConfirmationModalAccept = () => {
        loadSavedOrder(selectedOfferToLoad || '');
        dispatch(uSavedOrderId(selectedOfferToLoad || ''));
        setShowLoadConfirmationModal(false);
    };

    const handleLoadConfirmationModalDecline = () => {
        setShowLoadConfirmationModal(false);
        dispatch(makeNewOffer());
    };

    // Form change helpers
    const autoSaveTimeout = () => {
        if (blurTimeout) {
            clearTimeout(blurTimeout);
        }
        const timeout = setTimeout(() => {
            autoSaveForm(formSimpleDataRef.current);
            setBlurTimeout(null);
        }, 2000);
        setBlurTimeout(timeout);
    };

    const handleFormSimpleDataChange = (
        name: string,
        event: React.ChangeEvent<
            HTMLInputElement | { name?: string | undefined; value: unknown; type?: string; checked?: boolean }
        >,
    ) => {
        // Update state
        if (event.target.type === 'checkbox') {
            setFormSimpleData({ ...formSimpleData, [name]: event.target.checked });
        } else {
            if (name === 'newCustomerName') {
                newCustomerId = undefined;
            }
            setFormSimpleData({ ...formSimpleData, [name]: event.target.value });
        }

        // Handle autosave
        autoSaveTimeout();
    };

    const handleFormSimpleDataSelectChange = (name: string, event: SelectChangeEvent) => {
        // Update state
        setFormSimpleData({ ...formSimpleData, [name]: event.target.value });

        // Handle autosave
        autoSaveTimeout();
    };

    const handleGetGridData = (gridData: OGCellType[], gridHeader: OGColumnDef[]) => {
        setFormSimpleData({
            ...formSimpleData,
            gridData,
            gridHeader,
        });

        // Handle autosave
        autoSaveTimeout();
    };

    // File upload helper
    const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        const newFiles: IFile[] = [];
        if (files) {
            for (let i = 0; i < files.length; i++) {
                const newFile = files.item(i);
                if (newFile) {
                    // Use temporary id until api returns a real one
                    const tmpId = `${Date.now() + i}`;
                    newFiles.push({ id: tmpId, file: newFile, name: newFile.name, active: true });
                    dispatch(saveFile(tmpId, newFile, newFile.name));
                }
            }
        }
        setFormFiles([...formFiles, ...newFiles]);
    };

    // File delete helper
    const handleRemoveFile = (id: string) => {
        for (let i = 0; i < formFiles.length; i++) {
            const f2 = formFiles[i];

            if (id === f2.id) {
                // Remove from state
                setFormFiles([...formFiles.slice(0, i), ...formFiles.slice(i + 1, formFiles.length - 1)]);
                // Remove from server
                dispatch(removeFile(id));
            }
        }
    };

    const assembleOffer = (fsd: FormSimpleData) => {
        const { customerId, newCustomerName, reference, message, fast, deadlineWish, gridHeader, gridData } = fsd;

        const rows = [];
        const columns = [];
        for (let i = 0; i < gridHeader.length; i++) {
            columns.push(gridHeader[i].field);
        }
        for (let i = 0; i < gridData.length; i++) {
            // Check if row empty
            let empty = true;
            for (let j = 0; j < columns.length; j++) {
                if (gridData[i][columns[j]]) {
                    empty = false;
                }
            }
            if (!empty) {
                const row: { cells: { content: string | null }[] } = { cells: [] };
                for (let j = 1; j < columns.length; j++) {
                    row.cells.push({ content: gridData[i][columns[j]] });
                }
                rows.push(row);
            }
        }

        const data: OfferData = {
            reference,
            message,
            fast,
            deadlineWish,
        };

        if (customerId === '-1') {
            data.customer = {
                name: newCustomerName,
                id: newCustomerId || undefined,
            };
        } else if (customerId) {
            data.customer = {
                id: customerId,
                name: customers.find((c) => c.id === customerId)?.name || '',
            };
        }

        const columnNames = gridHeader.map((h) => ({ field: h.field })).slice(1);

        // Only add offer table if not empty
        if (rows.length > 0) {
            data.offerTable = {
                header: {
                    cells: columnNames,
                },
                rows,
            };
        }

        return data;
    };

    // Form autosave helpers
    const autoSaveForm = (fsd: FormSimpleData) => {
        // Create offer table
        const data = assembleOffer(fsd);

        dispatch(autoSave(data));
    };

    const handleShowSubmitConfirmationModal = () => {
        setShowConfirmationModal(true);
        if (blurTimeout) {
            clearTimeout(blurTimeout);
        }
        autoSaveForm(formSimpleDataRef.current);
    };

    // TODO: handle error
    // Submission helper
    const handleOfferSubmission = () => {
        if (blurTimeout) {
            clearTimeout(blurTimeout);
            autoSaveForm(formSimpleDataRef.current);
        }

        dispatch(submitOffer());

        // Clear everything
        setFormSimpleData(emptyFormSimpleData);
        setFormFiles([]);
        setLoadedData(null);

        navigate('/kiitos');
    };

    const submitDisabledText = (fsd: FormSimpleData) => {
        if (!fsd.reference) {
            return 'Lisää viite/merkki!';
        } else if (fsd.customerId === '-1' && !fsd.newCustomerName) {
            return 'Lisää nimi uudelle asiakkaalle!';
        } else {
            return false;
        }
    };

    return (
        <StyledContainer maxWidth='xl'>
            <Box my={2}>
                <Box my={2}>
                    <Typography variant='h1' component='h1'>
                        Tarjouspyyntö
                    </Typography>
                    <Grid container direction='column' spacing={2}>
                        {customers.length > 0 && (
                            <Grid item>
                                <FormControl>
                                    <InputLabel id='customer-label' shrink={true} required={true}>
                                        Asiakas
                                    </InputLabel>
                                    <Select
                                        id='customer'
                                        labelId='customer-label'
                                        className={classes.select}
                                        value={formSimpleData.customerId}
                                        onChange={(e) => handleFormSimpleDataSelectChange('customerId', e)}>
                                        {(myRole === Role.STAFF || myRole === Role.ADMIN
                                            ? customers.concat([
                                                  { id: '-1', name: 'Uusi asiakas', user: '-1', customUser: true },
                                              ])
                                            : customers
                                        ).map((c) => (
                                            <MenuItem key={c.id} value={c.id}>
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {formSimpleData.customerId === '-1' && (
                                    <Box py={1}>
                                        <TextField
                                            id='new-customer'
                                            label='Uusi asiakas'
                                            required={true}
                                            InputLabelProps={{ shrink: true }}
                                            value={formSimpleData.newCustomerName}
                                            onChange={(e) => handleFormSimpleDataChange('newCustomerName', e)}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        )}
                        <Grid item>
                            <TextField
                                id='reference'
                                className={classes.inputLong}
                                label='Viite/Merkki'
                                required={true}
                                InputLabelProps={{ shrink: true }}
                                value={formSimpleData.reference}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFormSimpleDataChange('reference', e)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id='offer-message'
                                className={classes.offerText}
                                multiline
                                rows={5}
                                placeholder='Voit kuvailla tähän vapaasti tarpeen ja/tai täyttää mittoja ja tietoja alla olevaan taulukkoon'
                                variant='outlined'
                                value={formSimpleData.message}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFormSimpleDataChange('message', e)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <OfferGrid callbackGetGridData={handleGetGridData} loadedData={loadedData} />
                        </Grid>
                        <Grid item>
                            <Typography component='p' gutterBottom>
                                Lisää tästä tilaukseen liittyviä tiedostoja (excel, kuvia, yms.)
                            </Typography>
                            <Button
                                variant='contained'
                                color='secondary'
                                component='label'
                                startIcon={<Icon>attach_file</Icon>}>
                                Lisää liite
                                <input type='file' hidden onChange={handleAddFiles} />
                            </Button>
                            <div /> {/* To clear file list from button */}
                            <List className={classes.fileList}>
                                {formFiles.map((f) => (
                                    <ListItem key={f.id}>
                                        <ListItemText className={classes.fileListText}>{f.name}</ListItemText>
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={() => handleRemoveFile(f.id)} size='large'>
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems='center'>
                                <Box>Tarvitsen tarjouksen viimeistään:</Box>
                                <form className={classes.dl_container} noValidate>
                                    <TextField
                                        id='offer-dl'
                                        className={classes.textField}
                                        type='date'
                                        value={formSimpleData.deadlineWish}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleFormSimpleDataChange('deadlineWish', e)
                                        }
                                        disabled={formSimpleData.fast}
                                    />
                                </form>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formSimpleData.fast}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                handleFormSimpleDataChange('fast', e)
                                            }
                                            name='fast'
                                            color='secondary'
                                        />
                                    }
                                    label='Pika'
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Box>{offerRequestInfo}</Box>
                        </Grid>
                        <Grid item>
                            <Box mt={1}>
                                <Tooltip
                                    open={!!submitDisabledText(formSimpleData) && !showLoadConfirmationModal}
                                    title={submitDisabledText(formSimpleData)}
                                    arrow>
                                    <span>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            disabled={
                                                !formSimpleData.reference ||
                                                (formSimpleData.customerId === '-1' && !formSimpleData.newCustomerName)
                                            }
                                            onClick={handleShowSubmitConfirmationModal}>
                                            Lähetä tarjouspyyntö
                                        </Button>
                                    </span>
                                </Tooltip>
                                <OfferInfoModal
                                    open={showConfirmationModal}
                                    offer={{
                                        ...assembleOffer(formSimpleDataRef.current),
                                        files: formFiles,
                                        id: offerId ?? undefined,
                                    }}
                                    handleClose={() => setShowConfirmationModal(false)}>
                                    <Box mt={1} style={{ width: '100%' }}>
                                        <Grid container justifyContent='flex-end' spacing={1}>
                                            <Grid item>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={handleOfferSubmission}>
                                                    Lähetä tarjouspyyntö
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    onClick={() => setShowConfirmationModal(false)}>
                                                    Peruuta
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </OfferInfoModal>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <LoadSavedOrderModal
                showLoadConfirmationModal={showLoadConfirmationModal}
                setShowLoadConfirmationModal={setShowLoadConfirmationModal}
                selectedOfferToLoad={selectedOfferToLoad}
                setSelectedOfferToLoad={setSelectedOfferToLoad}
                savedOrders={savedOrders}
                handleLoadConfirmationModalDecline={handleLoadConfirmationModalDecline}
                handleLoadConfirmationModalAccept={handleLoadConfirmationModalAccept}
            />
        </StyledContainer>
    );
};

export default Offer;
