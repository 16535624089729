import React, { FC, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Grid, Typography, Dialog } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch } from 'react-redux';

import { OfferInfoModalProps } from './types';
import { OfferData } from '../../redux/offer/types';
import { columnDefs } from '../offer-grid';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridRow } from '../offer-grid/types';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { useReactToPrint } from 'react-to-print';

import { downloadOfferFile } from '../../redux/offer/actions';

const PREFIX = 'OfferInfoModal';

const classes = {
    modal: `${PREFIX}-modal`,
    modalTitle: `${PREFIX}-modalTitle`,
    modalLabel: `${PREFIX}-modalLabel`,
    modalRow: `${PREFIX}-modalRow`,
    gridWrapper: `${PREFIX}-gridWrapper`,
    actionButtons: `${PREFIX}-actionButtons`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.modal}`]: {
        maxWidth: '1400px',
        padding: theme.spacing(2),
    },

    [`& .${classes.modalTitle}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.modalLabel}`]: {
        fontWeight: theme.typography.fontWeightMedium,
    },

    [`& .${classes.modalRow}`]: {
        marginBottom: theme.spacing(1),
    },

    [`& .${classes.gridWrapper}`]: {
        width: 50,
    },

    [`& .${classes.actionButtons}`]: {
        '@media print': {
            display: 'none',
        },
    },

    '.ag-theme-alpine .ag-header-cell.ag-header-cell.ag-header-cell:not(:last-child)': {
        borderRight: '1px solid #dde2eb',
    },
    '.ag-theme-alpine .ag-cell.ag-cell.ag-cell:not(:last-child)': {
        borderRight: '1px solid #dde2eb',
    },
    '.ag-theme-alpine .ag-row.ag-row.ag-row:hover': {
        backgroundColor: 'white',
    },
    '.ag-theme-alpine .ag-row.ag-row.ag-row:last-child': {
        borderBottom: 'none',
    },
    '.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper': {
        minHeight: 'initial',
    },

    '@media print': {
        '@page': {
            size: 'landscape',
        },

        '.ag-center-cols-viewport': {
            overflow: 'hidden',
        },
    },
}));

const defaultColDef = {
    width: 100,
};

const offerToRowData = (offer: OfferData): GridRow[] => {
    const { offerTable } = offer;
    if (offerTable) {
        const rowData: GridRow[] = [];
        for (let i = 0; i < offerTable.rows.length; i++) {
            const newRow: GridRow = {};
            for (let j = 0; j < offerTable.header.cells.length; j++) {
                newRow[offerTable.header.cells[j].field] = offerTable.rows[i].cells[j].content;
            }
            rowData.push(newRow);
        }
        return rowData;
    }
    return [];
};

const OfferInfoModal: FC<OfferInfoModalProps> = (props) => {
    const { open, offer, handleClose, children } = props;

    const dispatch = useDispatch();
    const ref = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });

    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const downloadTable = () => {
        gridApi?.exportDataAsCsv({
            columnSeparator: ';', // CSV separator for finnish Windows + Excel
        });
    };

    return (
        <StyledDialog
            classes={{ paper: classes.modal }}
            open={open}
            onClose={handleClose}
            ref={ref}
            aria-labelledby='tilauksen tiedot'
            aria-describedby='simple-modal-description'>
            {offer ? (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography component='h2' variant='h2' className={classes.modalTitle}>
                            Tarjouspyynnön tiedot {offer.number ? '#' + offer.number : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.modalRow}>
                        <Grid container>
                            <Grid item xs={2}>
                                <span className={classes.modalLabel}>Asiakas:</span>
                            </Grid>
                            <Grid item xs={10}>
                                {offer.customer?.name || '-'}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.modalRow}>
                        <Grid container>
                            <Grid item xs={2}>
                                <span className={classes.modalLabel}>Viite/Merkki:</span>
                            </Grid>
                            <Grid item xs={2}>
                                {offer.reference || '-'}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.modalRow}>
                        <Grid container>
                            <Grid item xs={2}>
                                <span className={classes.modalLabel}>Jätetty:</span>
                            </Grid>
                            <Grid item xs={10}>
                                {offer.submissionTime ? new Date(offer.submissionTime).toLocaleString() : '-'}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.modalRow}>
                        <Grid container>
                            <Grid item xs={2}>
                                <span className={classes.modalLabel}>Halutaan viimeistään:</span>
                            </Grid>
                            <Grid item xs={10}>
                                {offer.fast
                                    ? 'PIKA'
                                    : offer.deadlineWish
                                    ? new Date(offer.deadlineWish).toLocaleDateString()
                                    : '-'}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.modalRow}>
                        <Grid container>
                            <Grid item xs={2}>
                                <span className={classes.modalLabel}>Viesti:</span>
                            </Grid>
                            <Grid item xs={10}>
                                <div>{offer.message || '-'}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.modalRow}>
                        <Grid container>
                            <Grid item xs={2}>
                                <span className={classes.modalLabel}>Liitteet:</span>
                            </Grid>
                            <Grid item xs={10}>
                                {offer.files && offer.files.length > 0
                                    ? offer.files.map((f, i) => (
                                          <span key={f.id}>
                                              {i ? ', ' : ''}
                                              <a
                                                  onClick={() =>
                                                      dispatch(downloadOfferFile(offer.id || '', f.id, f.name))
                                                  }
                                                  href='javaScript:void(0)'>
                                                  {f.name}
                                              </a>
                                          </span>
                                      ))
                                    : '-'}
                            </Grid>
                        </Grid>
                    </Grid>
                    {offerToRowData(offer).length > 0 && (
                        <Grid item xs={12} className={`ag-theme-alpine ${classes.modalRow}`}>
                            <AgGridReact
                                rowData={offerToRowData(offer)}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs.slice(1)}
                                suppressRowClickSelection={true}
                                domLayout={'autoHeight'}
                                onGridReady={onGridReady}
                            />
                        </Grid>
                    )}
                    {children}
                    {!children && (
                        <Grid container justifyContent='flex-end' spacing={1} className={classes.actionButtons}>
                            {offerToRowData(offer).length > 0 && (
                                <Grid item>
                                    <Button variant='contained' color='primary' onClick={downloadTable}>
                                        Lataa CSV
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button variant='contained' color='primary' onClick={handlePrint}>
                                    Tulosta
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            ) : (
                <div />
            )}
        </StyledDialog>
    );
};

export default OfferInfoModal;
