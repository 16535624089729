import { combineEpics, Epic } from 'redux-observable';
import { filter, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { api } from '../../utils/api';
import { localToUtc, utcToLocal } from '../../utils/timezoneConverter';

import {
    DELETE_EMAIL_TIMING_SUCCESS,
    Email,
    GET_CONFIG,
    GET_CONFIG_SUCCESS,
    GET_EMAILS,
    GET_EMAILS_SUCCESS,
    REMOVE_EMAIL,
    REMOVE_EMAIL_SUCCESS,
    SettingsActionTypes,
    SettingsState,
    SimpleData,
    SUBMIT_NEW_EMAIL,
    SUBMIT_NEW_EMAIL_SUCCESS,
    SUBMIT_NEW_EMAIL_TIMING_SUCCESS,
    UPDATE_CONFIG,
    UPDATE_CONFIG_SUCCESS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_EMAIL_TIMING_SUCCESS,
} from './types';

import {
    deleteEmailTimingError,
    deleteEmailTimingSuccess,
    getConfigError,
    getConfigSuccess,
    getEmailsError,
    getEmailsSuccess,
    removeEmailError,
    removeEmailSuccess,
    submitNewEmailError,
    submitNewEmailSuccess,
    submitNewEmailTimingError,
    submitNewEmailTimingSuccess,
    updateConfigError,
    updateConfigSuccess,
    updateEmailError,
    updateEmailSuccess,
    updateEmailTimingError,
    updateEmailTimingSuccess,
} from './actions';
import { RootState } from '..';

const initialState: SettingsState = {
    simpleData: {},
    emails: undefined,
};

const settings = (state = initialState, action: SettingsActionTypes): SettingsState => {
    switch (action.type) {
        case GET_CONFIG_SUCCESS:
            return {
                ...state,
                simpleData: {
                    deadlineWishDefaultAfterDays: action.payload.deadlineWishDefaultAfterDays,
                    offerRequestInfo: action.payload.offerRequestInfo,
                    tawkToLink: action.payload.tawkToLink,
                    tawkToHash: action.payload.tawkToHash,
                    emailSender: action.payload.emailSender,
                    infoPdfName: action.payload.infoPdfName,
                },
            };
        case GET_EMAILS_SUCCESS:
            return { ...state, emails: action.payload.emails };
        default:
            return state;
    }
};

export default settings;

export const getConfigEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType([GET_CONFIG, UPDATE_CONFIG_SUCCESS])),
        mergeMap(() =>
            api.get<SimpleData>(`/config`).pipe(
                map((res) => getConfigSuccess(res)),
                catchError((error) => of(getConfigError(error))),
            ),
        ),
    );

export const updateConfigEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(UPDATE_CONFIG)),
        map((action) => ({
            name: action.payload.name,
            value: action.payload.value,
        })),
        mergeMap((r) =>
            api.patch(`/config/${r.name}`, { [r.name]: r.value }).pipe(
                map(() => updateConfigSuccess()),
                catchError((error) => of(updateConfigError(error))),
            ),
        ),
    );

export const getEmailsEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(
            isOfType([
                GET_EMAILS,
                SUBMIT_NEW_EMAIL_TIMING_SUCCESS,
                UPDATE_EMAIL_TIMING_SUCCESS,
                DELETE_EMAIL_TIMING_SUCCESS,
                REMOVE_EMAIL_SUCCESS,
            ]),
        ),
        mergeMap(() =>
            api.get<Email[]>(`/email`).pipe(
                map((res) => ({
                    ...res,
                    response: res.response.map((email) => {
                        email.timings = email.timings.map((timing) => {
                            timing['sendTime'] = utcToLocal(timing['sendTime']);
                            return timing;
                        });
                        return email;
                    }),
                })),
                map((res) => getEmailsSuccess(res)),
                catchError((error) => of(getEmailsError(error))),
            ),
        ),
    );

export const submitNewEmailEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(SUBMIT_NEW_EMAIL)),
        map((action) => {
            const { timings, ...body } = action.payload.newEmail;
            return {
                body,
                addTimings: timings.map((timing) => ({
                    ...timing,
                    sendTime: localToUtc(timing.sendTime),
                })),
                updateTimings: [],
                deleteTimings: [],
            };
        }),
        mergeMap((r) =>
            api.post<Email>(`/email`, { ...r.body }).pipe(
                map((res) =>
                    submitNewEmailSuccess(res.response.id as string, r.addTimings, r.updateTimings, r.deleteTimings),
                ),
                catchError((error) => of(submitNewEmailError(error))),
            ),
        ),
    );

export const updateEmailTimingEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType([SUBMIT_NEW_EMAIL_SUCCESS, UPDATE_EMAIL_SUCCESS])),
        mergeMap((action) => {
            if (action.payload.updateTimings.length > 0) {
                return from(action.payload.updateTimings).pipe(
                    mergeMap((timing) =>
                        api.put(`/email/${action.payload.id}/timing/${timing.id}`, { ...timing }).pipe(
                            map(() => updateEmailTimingSuccess()),
                            catchError((error) => of(updateEmailTimingError(error))),
                        ),
                    ),
                );
            }
            return of(updateEmailTimingSuccess());
        }),
    );

export const deleteEmailTimingEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType([SUBMIT_NEW_EMAIL_SUCCESS, UPDATE_EMAIL_SUCCESS])),
        mergeMap((action) => {
            if (action.payload.deleteTimings.length > 0) {
                return from(action.payload.deleteTimings).pipe(
                    mergeMap((timing) =>
                        api.delete(`/email/${action.payload.id}/timing/${timing.id}`).pipe(
                            map(() => deleteEmailTimingSuccess()),
                            catchError((error) => of(deleteEmailTimingError(error))),
                        ),
                    ),
                );
            }
            return of(deleteEmailTimingSuccess());
        }),
    );

export const submitNewEmailTimingEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType([SUBMIT_NEW_EMAIL_SUCCESS, UPDATE_EMAIL_SUCCESS])),
        mergeMap((action) => {
            if (action.payload.addTimings.length > 0) {
                return from(action.payload.addTimings).pipe(
                    mergeMap((timing) =>
                        api.post(`/email/${action.payload.id}/timing`, { ...timing }).pipe(
                            map(() => submitNewEmailTimingSuccess()),
                            catchError((error) => of(submitNewEmailTimingError(error))),
                        ),
                    ),
                );
            }
            return of(submitNewEmailTimingSuccess());
        }),
    );

export const removeEmailEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType([REMOVE_EMAIL])),
        mergeMap((action) =>
            api.delete(`/email/${action.payload.id}`).pipe(
                map(() => removeEmailSuccess()),
                catchError((error) => of(removeEmailError(error))),
            ),
        ),
    );

export const updateEmailEpic: Epic<SettingsActionTypes, SettingsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(UPDATE_EMAIL)),
        map((action) => {
            const { timings, ...body } = action.payload.email;
            return {
                body,
                addTimings: action.payload.addTimings.map((timing) => ({
                    ...timing,
                    sendTime: localToUtc(timing.sendTime),
                })),
                updateTimings: action.payload.updateTimings.map((timing) => ({
                    ...timing,
                    sendTime: localToUtc(timing.sendTime),
                })),
                deleteTimings: action.payload.deleteTimings.map((timing) => ({
                    ...timing,
                    sendTime: localToUtc(timing.sendTime),
                })),
            };
        }),
        mergeMap((r) =>
            api.put<Email>(`/email/${r.body.id}`, { ...r.body }).pipe(
                map((res) =>
                    updateEmailSuccess(res.response.id as string, r.addTimings, r.updateTimings, r.deleteTimings),
                ),
                catchError((error) => of(updateEmailError(error))),
            ),
        ),
    );

export const epics = combineEpics(
    getConfigEpic,
    updateConfigEpic,
    getEmailsEpic,
    submitNewEmailEpic,
    submitNewEmailTimingEpic,
    updateEmailTimingEpic,
    deleteEmailTimingEpic,
    removeEmailEpic,
    updateEmailEpic,
);
