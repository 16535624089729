import { Action, EmptyObject } from 'redux';
import { AjaxResponse, AjaxError } from 'rxjs/ajax';

// Other
export interface Customer {
    id: string;
    name: string;
    user: string;
    customUser: boolean;
}

// State
export interface OfferState {
    id: null | string;
    savedOrders: null | OfferData[];
    fileIdReplace?: { tmpId: string; id: string };
    customers: Customer[];
    newCustomerId?: string;
}

// Action types
export const MAKE_NEW_OFFER = 'offer:MAKE_NEW_OFFER';
export const MAKE_NEW_OFFER_SUCCESS = 'offer:MAKE_NEW_OFFER_SUCCESS';
export const MAKE_NEW_OFFER_ERROR = 'offer:MAKE_NEW_OFFER_ERROR';
export const USE_SAVED_ORDER_ID = 'offer:USE_SAVED_ORDER_ID';
export const GET_SAVED_ORDERS = 'offer:GET_SAVED_ORDERS';
export const GET_SAVED_ORDERS_SUCCESS = 'offer:GET_SAVED_ORDERS_SUCCESS';
export const GET_SAVED_ORDERS_ERROR = 'offer:GET_SAVED_ORDERS_ERROR';
export const AUTO_SAVE = 'offer:AUTO_SAVE';
export const AUTO_SAVE_SUCCESS = 'offer:AUTO_SAVE_SUCCESS';
export const AUTO_SAVE_ERROR = 'offer:AUTO_SAVE_ERROR';
export const SAVE_FILE = 'offer:SAVE_FILE';
export const SAVE_FILE_SUCCESS = 'offer:SAVE_FILE_SUCCESS';
export const SAVE_FILE_ERROR = 'offer:SAVE_FILE_ERROR';
export const REMOVE_FILE = 'offer:REMOVE_FILE';
export const REMOVE_FILE_SUCCESS = 'offer:REMOVE_FILE_SUCCESS';
export const REMOVE_FILE_ERROR = 'offer:REMOVE_FILE_ERROR';
export const SUBMIT_OFFER = 'offer:SUBMIT_OFFER';
export const SUBMIT_OFFER_SUCCESS = 'offer:SUBMIT_OFFER_SUCCESS';
export const SUBMIT_OFFER_ERROR = 'offer:SUBMIT_OFFER_ERROR';
export const GET_CUSTOMERS = 'offer:GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = 'offer:GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'offer:GET_CUSTOMERS_ERROR';
export const DOWNLOAD_OFFER_FILE = 'my-offers:DOWNLOAD_OFFER_FILE';
export const DOWNLOAD_OFFER_FILE_SUCCESS = 'my-offers:DOWNLOAD_OFFER_FILE_SUCCESS';
export const DOWNLOAD_OFFER_FILE_ERROR = 'my-offers:DOWNLOAD_OFFER_FILE_ERROR';

// Payloads
export interface OGCellType {
    [tmpId: string]: string | null;
}

export interface OGRowType {
    cells: OGCellType[];
}

export interface OGColumnDef {
    field: string;
    checkboxSelection?: boolean;
    suppressSizeToFit?: boolean;
    width?: number;
    flex?: number;
    editable?: boolean;
    cellEditor?: string;
    cellEditorParams?: Record<string, number>;
}

export interface IFile {
    id: string;
    file?: File;
    name: string;
    active: boolean;
}

export interface OfferData {
    id?: string;
    number?: number;
    customer?: {
        id?: string;
        name?: string;
        user?: string;
        customUser?: boolean;
    };
    reference?: string;
    message?: string;
    fast?: boolean;
    deadlineWish?: string;
    offerTable?: {
        header: {
            cells: OGColumnDef[];
        };
        rows: OGRowType[];
    };
    files?: IFile[];
    created?: string;
    updated?: string;
    submissionTime?: string;
    completionTime?: string;
    status?: string;
    assignments?: {
        offerRequest: string;
        user: string;
        status: string;
        created: string;
        updated?: string;
        reason?: string;
        affectTime: string;
    }[];
}

// Actions
export interface MakeNewOfferAction extends Action {
    type: typeof MAKE_NEW_OFFER;
}

export interface MakeNewOfferSuccessAction extends Action {
    type: typeof MAKE_NEW_OFFER_SUCCESS;
    payload: {
        id: string;
    };
}

export interface MakeNewOfferErrorAction extends Action {
    type: typeof MAKE_NEW_OFFER_ERROR;
    error: AjaxError;
}

export interface GetSavedOrderAction extends Action {
    type: typeof GET_SAVED_ORDERS;
}

export interface GetSavedOrderSuccessAction extends Action {
    type: typeof GET_SAVED_ORDERS_SUCCESS;
    payload: {
        savedOrders: OfferData[];
    };
}

export interface GetSavedOrderErrorAction extends Action {
    type: typeof GET_SAVED_ORDERS_ERROR;
    error: AjaxError;
}

export interface UseSavedOrderIdAction extends Action {
    type: typeof USE_SAVED_ORDER_ID;
    payload: {
        id: string;
    };
}

export interface AutoSaveAction extends Action {
    type: typeof AUTO_SAVE;
    payload: {
        data: OfferData;
    };
}

export interface AutoSaveSuccessAction extends Action {
    type: typeof AUTO_SAVE_SUCCESS;
    payload: {
        newCustomerId: string;
    };
}

export interface AutoSaveErrorAction extends Action {
    type: typeof AUTO_SAVE_ERROR;
    error: AjaxError;
}

export interface SaveFileAction extends Action {
    type: typeof SAVE_FILE;
    payload: {
        file: File;
        tmpId: string;
        name: string;
    };
}

export interface SaveFileSuccessAction extends Action {
    type: typeof SAVE_FILE_SUCCESS;
    payload: {
        tmpId: string;
        id: string;
    };
}

export interface SaveFileErrorAction extends Action {
    type: typeof SAVE_FILE_ERROR;
    error: AjaxError;
}

export interface RemoveFileAction extends Action {
    type: typeof REMOVE_FILE;
    payload: {
        id: string;
    };
}

export interface RemoveFileSuccessAction extends Action {
    type: typeof REMOVE_FILE_SUCCESS;
}

export interface RemoveFileErrorAction extends Action {
    type: typeof REMOVE_FILE_ERROR;
    error: AjaxError;
}

export interface SubmitOfferAction extends Action {
    type: typeof SUBMIT_OFFER;
}

export interface SubmitOfferSuccessAction extends Action {
    type: typeof SUBMIT_OFFER_SUCCESS;
    payload: AjaxResponse<EmptyObject>;
}

export interface SubmitOfferErrorAction extends Action {
    type: typeof SUBMIT_OFFER_ERROR;
    error: AjaxError;
}

export interface GetCustomersAction extends Action {
    type: typeof GET_CUSTOMERS;
}

export interface GetCustomersSuccessAction extends Action {
    type: typeof GET_CUSTOMERS_SUCCESS;
    payload: {
        customers: Customer[];
    };
}

export interface GetCustomersErrorAction extends Action {
    type: typeof GET_CUSTOMERS_ERROR;
    error: AjaxError;
}

export interface DownloadOfferFileAction extends Action {
    type: typeof DOWNLOAD_OFFER_FILE;
    payload: {
        offerId: string;
        fileId: string;
        name: string;
    };
}

export interface DownloadOfferFileSuccessAction extends Action {
    type: typeof DOWNLOAD_OFFER_FILE_SUCCESS;
}

export interface DownloadOfferFileErrorAction extends Action {
    type: typeof DOWNLOAD_OFFER_FILE_ERROR;
    error: AjaxError;
}

export type OfferActionTypes =
    | MakeNewOfferAction
    | MakeNewOfferSuccessAction
    | MakeNewOfferErrorAction
    | GetSavedOrderAction
    | GetSavedOrderErrorAction
    | GetSavedOrderSuccessAction
    | UseSavedOrderIdAction
    | AutoSaveAction
    | AutoSaveSuccessAction
    | AutoSaveErrorAction
    | SaveFileAction
    | SaveFileSuccessAction
    | SaveFileErrorAction
    | RemoveFileAction
    | RemoveFileSuccessAction
    | RemoveFileErrorAction
    | SubmitOfferAction
    | SubmitOfferSuccessAction
    | SubmitOfferErrorAction
    | GetCustomersAction
    | GetCustomersSuccessAction
    | GetCustomersErrorAction
    | DownloadOfferFileAction
    | DownloadOfferFileSuccessAction
    | DownloadOfferFileErrorAction;
