import { combineEpics, Epic } from 'redux-observable';
import { filter, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { api } from '../../utils/api';

import {
    ANSWER_PROPOSAL,
    ANSWER_PROPOSAL_SUCCESS,
    CHANGE_PAGE,
    COMPLETE_OFFER,
    COMPLETE_OFFER_SUCCESS,
    GET_MY_OFFERS,
    GET_MY_OFFERS_SUCCESS,
    MyOffersActionTypes,
    MyOffersState,
} from './types';

import {
    answerProposalError,
    answerProposalSuccess,
    completeOfferError,
    completeOfferSuccess,
    getMyOffersError,
    getMyOffersSuccess,
} from './actions';
import { RootState } from '..';
import { OfferData } from '../offer/types';

const initialState: MyOffersState = {
    proposed: {},
    proposedPage: 0,
    proposedSize: 0,
    accepted: {},
    acceptedPage: 0,
    acceptedSize: 0,
    done: {},
    donePage: 0,
    doneSize: 0,
};

const offer = (state = initialState, action: MyOffersActionTypes): MyOffersState => {
    switch (action.type) {
        case CHANGE_PAGE:
            return { ...state, [`${action.payload.type}Page`]: action.payload.page };
        case GET_MY_OFFERS_SUCCESS:
            return {
                ...state,
                [action.payload.type]: {
                    ...(state[action.payload.type] as { [i: number]: OfferData[] }),
                    [state[`${action.payload.type}Page`] as number]: action.payload.offers,
                },
                [`${action.payload.type}Size`]: action.payload.size,
            };
        default:
            return state;
    }
};

export default offer;

export const getMyProposedOffersEpic: Epic<MyOffersActionTypes, MyOffersActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(GET_MY_OFFERS)),
        mergeMap(() =>
            api
                .get<OfferData[]>(
                    '/offer',
                    {
                        filter: { assignmentStatus: 'WAITING', handler: state$.value.user.id || '' },
                        sort: 'created,ASC',
                        page: `${state$.value.myOffers.proposedPage}`,
                        size: '10',
                    },
                    {},
                )
                .pipe(
                    map((res) => getMyOffersSuccess(res, 'proposed')),
                    catchError((error) => of(getMyOffersError(error))),
                ),
        ),
    );

export const getMyAcceptedOffersEpic: Epic<MyOffersActionTypes, MyOffersActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(GET_MY_OFFERS)),
        mergeMap(() =>
            api
                .get<OfferData[]>(
                    '/offer',
                    {
                        filter: {
                            assignmentStatus: 'ACCEPT',
                            status: 'HANDLING',
                            handler: state$.value.user.id || '',
                        },
                        sort: 'created,ASC',
                        page: `${state$.value.myOffers.acceptedPage}`,
                        size: '10',
                    },
                    {},
                )
                .pipe(
                    map((res) => getMyOffersSuccess(res, 'accepted')),
                    catchError((error) => of(getMyOffersError(error))),
                ),
        ),
    );

export const getMyDoneOffersEpic: Epic<MyOffersActionTypes, MyOffersActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(GET_MY_OFFERS)),
        mergeMap(() =>
            api
                .get<OfferData[]>(
                    '/offer',
                    {
                        filter: {
                            assignmentStatus: 'ACCEPT',
                            status: 'DONE',
                            handler: state$.value.user.id || '',
                        },
                        sort: 'updated,DESC',
                        page: `${state$.value.myOffers.donePage}`,
                        size: '10',
                    },
                    {},
                )
                .pipe(
                    map((res) => getMyOffersSuccess(res, 'done')),
                    catchError((error) => of(getMyOffersError(error))),
                ),
        ),
    );

export const refreshMyOffersEpic: Epic<MyOffersActionTypes, MyOffersActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType([ANSWER_PROPOSAL_SUCCESS, COMPLETE_OFFER_SUCCESS])),
        mergeMap(() => {
            return from([
                // Proposed
                ...[...Array(state$.value.myOffers.proposedPage + 1).keys()].map((i) => ({
                    type: 'proposed',
                    assignmentStatus: 'WAITING',
                    status: '',
                    sort: 'created,ASC',
                    page: `${i}`,
                })),
                // Accepted
                ...[...Array(state$.value.myOffers.acceptedPage + 1).keys()].map((i) => ({
                    type: 'accepted',
                    assignmentStatus: 'ACCEPT',
                    status: 'HANDLING',
                    sort: 'created,ASC',
                    page: `${i}`,
                })),
                // Done
                {
                    type: 'done',
                    assignmentStatus: 'ACCEPT',
                    status: 'DONE',
                    sort: 'updated,DESC',
                    page: `${state$.value.myOffers.donePage}`,
                },
            ]).pipe<MyOffersActionTypes>(
                mergeMap((t) =>
                    api
                        .get<OfferData[]>(
                            '/offer',
                            {
                                filter: {
                                    assignmentStatus: t.assignmentStatus,
                                    status: t.status,
                                    handler: state$.value.user.id || '',
                                },
                                sort: t.sort,
                                page: t.page,
                                size: '10',
                            },
                            {},
                        )
                        .pipe(
                            map((res) => getMyOffersSuccess(res, t.type)),
                            catchError((error) => of(getMyOffersError(error))),
                        ),
                ),
            );
        }),
    );

export const answerProposalEpic: Epic<MyOffersActionTypes, MyOffersActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(ANSWER_PROPOSAL)),
        mergeMap((action) =>
            api
                .patch(
                    `/offer/${action.payload.id}/${action.payload.decision}`,
                    action.payload.reason ? { reason: action.payload.reason } : {},
                )
                .pipe(
                    map(() => answerProposalSuccess()),
                    catchError((error) => of(answerProposalError(error))),
                ),
        ),
    );

export const completeOfferEpic: Epic<MyOffersActionTypes, MyOffersActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(COMPLETE_OFFER)),
        mergeMap((action) =>
            api.patch(`/offer/${action.payload.id}/done`, {}).pipe(
                map(() => completeOfferSuccess()),
                catchError((error) => of(completeOfferError(error))),
            ),
        ),
    );

export const epics = combineEpics(
    getMyProposedOffersEpic,
    getMyAcceptedOffersEpic,
    getMyDoneOffersEpic,
    refreshMyOffersEpic,
    answerProposalEpic,
    completeOfferEpic,
);
