import { AnyAction, combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { reducer as oidcReducer } from 'redux-oidc';

import user, { epics as userEpics } from './user';
import offer, { epics as offerEpics } from './offer';
import assign, { epics as assignEpics } from './assign';
import myOffers, { epics as myOffersEpics } from './my-offers';
import settings, { epics as settingsEpics } from './settings';
import statistics, { epics as statisticEpics } from './statistics';

const rootReducer = combineReducers({
    oidc: oidcReducer,
    user,
    offer,
    assign,
    myOffers,
    settings,
    statistics,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export const rootEpic = combineEpics<AnyAction, AnyAction, RootState>(
    userEpics,
    offerEpics,
    assignEpics,
    myOffersEpics,
    settingsEpics,
    statisticEpics,
);
