import { from, Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { map, mergeMap } from 'rxjs/operators';
import { User } from 'oidc-client';

import userManager from './userManager';

const defaultUrlRoot = window.config.apiUrl;

const userToAuthToken = (user: User | null) =>
    user ? { Authorization: `${user.token_type} ${user.access_token}` } : {};

const defaultHeaders = {
    'Content-type': 'Application/json',
};

const get = <T>(
    url: string,
    queryParameters: { [k: string]: string | { [k: string]: string | string[] } } = {},
    headers: { [k: string]: string } = {},
): Observable<AjaxResponse<T>> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) => {
            // Construct query parameter string
            const params = Object.keys(queryParameters);
            let parameterString = '';
            for (let i = 0; i < params.length; i++) {
                if (parameterString === '') {
                    parameterString += '?';
                } else {
                    parameterString += '&';
                }
                // Without this, non-objects get unnecessary "" around them
                if (typeof queryParameters[params[i]] === 'string') {
                    // Typescript wont recognize this as string without <string>
                    parameterString += `${params[i]}=${encodeURIComponent(queryParameters[params[i]] as string)}`;
                } else {
                    parameterString += `${params[i]}=${encodeURIComponent(JSON.stringify(queryParameters[params[i]]))}`;
                }
            }

            return ajax<T>({
                method: 'GET',
                url: `${defaultUrlRoot}${url}${parameterString}`,
                headers: { ...defaultHeaders, ...authHeaders, ...headers },
            });
        }),
    );

const post = <T>(
    url: string,
    body: Record<string, unknown>,
    headers: { [k: string]: string } = {},
): Observable<AjaxResponse<T>> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax<T>({
                method: 'POST',
                url: `${defaultUrlRoot}${url}`,
                body: JSON.stringify(body),
                headers: { ...defaultHeaders, ...authHeaders, ...headers },
            }),
        ),
    );

const patch = <T>(
    url: string,
    body: Record<string, unknown>,
    headers: { [k: string]: string } = {},
): Observable<AjaxResponse<T>> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax<T>({
                method: 'PATCH',
                url: `${defaultUrlRoot}${url}`,
                body: JSON.stringify(body),
                headers: { ...defaultHeaders, ...authHeaders, ...headers },
            }),
        ),
    );

const put = <T>(
    url: string,
    body: Record<string, unknown>,
    headers: { [k: string]: string } = {},
): Observable<AjaxResponse<T>> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax<T>({
                method: 'PUT',
                url: `${defaultUrlRoot}${url}`,
                body: JSON.stringify(body),
                headers: { ...defaultHeaders, ...authHeaders, ...headers },
            }),
        ),
    );

// delete not allowed as variable name
const apiDelete = <T>(url: string, headers: { [k: string]: string } = {}): Observable<AjaxResponse<T>> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax<T>({
                method: 'DELETE',
                url: `${defaultUrlRoot}${url}`,
                headers: { ...defaultHeaders, ...authHeaders, ...headers },
            }),
        ),
    );

const blob = (url: string, headers: { [k: string]: string } = {}): Observable<AjaxResponse<ArrayBuffer>> =>
    from(userManager.getUser()).pipe(
        map((user) => userToAuthToken(user)),
        mergeMap((authHeaders) =>
            ajax<ArrayBuffer>({
                method: 'GET',
                url: `${defaultUrlRoot}${url}`,
                headers: { ...defaultHeaders, ...authHeaders, ...headers },
                responseType: 'arraybuffer',
            }),
        ),
    );

export const api = {
    get,
    post,
    patch,
    put,
    delete: apiDelete,
    blob,
};
