import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';
import { OfferRequestStatus } from '../settings/types';

// Other types

export interface StatisticsOffer {
    id: string;
    number: number;
    reference: string;
    status: keyof typeof OfferRequestStatus;
    submitted: string;
    assigned: string;
    accepted: string;
    done: string;
    customer: string;
    handler: string;
    timeToAssign: number;
    timeToAccept: number;
    timeToDone: number;
    onTime: boolean;
}

export interface Statistics {
    openCount: number;
    handlingCount: number;
    readyCount: number;
    avgAssignTime: number;
    avgAcceptTime: number;
    avgReadyTime: number;
    offers?: StatisticsOffer[];
}

// State
export interface StatisticsState {
    offers: StatisticsOffer[];
    statistics?: Statistics;
}

// Action types
export const GET_STATISTICS = 'statistics:GET_STATISTICS';
export const GET_STATISTICS_SUCCESS = 'statistics:GET_STATISTICS_SUCCESS';
export const GET_STATISTICS_ERROR = 'statistics:GET_STATISTICS_ERROR';

// Actions
export interface GetStatisticsAction extends Action {
    type: typeof GET_STATISTICS;
}

export interface GetStatisticsSuccessAction extends Action {
    type: typeof GET_STATISTICS_SUCCESS;
    payload: {
        statistics: Statistics;
    };
}

export interface GetStatisticsErrorAction extends Action {
    type: typeof GET_STATISTICS_ERROR;
    error: AjaxError;
}

export type StatisticsActionTypes = GetStatisticsAction | GetStatisticsSuccessAction | GetStatisticsErrorAction;
