import { combineEpics, Epic } from 'redux-observable';
import { filter, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { api } from '../../utils/api';

import { getStatisticsError, getStatisticsSuccess } from './actions';
import { GET_STATISTICS, GET_STATISTICS_SUCCESS, Statistics, StatisticsActionTypes, StatisticsState } from './types';
import { RootState } from '..';

const initialState: StatisticsState = {
    offers: [],
    statistics: undefined,
};

const statistics = (state = initialState, action: StatisticsActionTypes): StatisticsState => {
    switch (action.type) {
        case GET_STATISTICS_SUCCESS: {
            const { offers, ...statistics } = action.payload.statistics;
            return { ...state, offers: offers ?? [], statistics };
        }
        default:
            return state;
    }
};

export default statistics;

export const getStatisticsEpic: Epic<StatisticsActionTypes, StatisticsActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(GET_STATISTICS)),
        mergeMap(() =>
            api.get<Statistics>('/statistic', { status: 'DONE' }).pipe(
                map((res) => getStatisticsSuccess(res)),
                catchError((error) => of(getStatisticsError(error))),
            ),
        ),
    );

export const epics = combineEpics(getStatisticsEpic);
