import { AjaxResponse, AjaxError } from 'rxjs/ajax';

import {
    Email,
    TimingObj,
    GET_CONFIG,
    GET_CONFIG_SUCCESS,
    GET_CONFIG_ERROR,
    GetConfigAction,
    GetConfigSuccessAction,
    GetConfigErrorAction,
    UPDATE_CONFIG,
    UPDATE_CONFIG_SUCCESS,
    UPDATE_CONFIG_ERROR,
    UpdateConfigAction,
    UpdateConfigSuccessAction,
    UpdateConfigErrorAction,
    GET_EMAILS,
    GET_EMAILS_SUCCESS,
    GET_EMAILS_ERROR,
    GetEmailsAction,
    GetEmailsSuccessAction,
    GetEmailsErrorAction,
    SUBMIT_NEW_EMAIL,
    SUBMIT_NEW_EMAIL_SUCCESS,
    SUBMIT_NEW_EMAIL_ERROR,
    SubmitNewEmailAction,
    SubmitNewEmailSuccessAction,
    SubmitNewEmailErrorAction,
    SUBMIT_NEW_EMAIL_TIMING_SUCCESS,
    SUBMIT_NEW_EMAIL_TIMING_ERROR,
    SubmitNewEmailTimingSuccessAction,
    SubmitNewEmailTimingErrorAction,
    UPDATE_EMAIL_TIMING_SUCCESS,
    UPDATE_EMAIL_TIMING_ERROR,
    UpdateEmailTimingSuccessAction,
    UpdateEmailTimingErrorAction,
    DELETE_EMAIL_TIMING_SUCCESS,
    DELETE_EMAIL_TIMING_ERROR,
    DeleteEmailTimingSuccessAction,
    DeleteEmailTimingErrorAction,
    REMOVE_EMAIL,
    REMOVE_EMAIL_SUCCESS,
    REMOVE_EMAIL_ERROR,
    RemoveEmailAction,
    RemoveEmailSuccessAction,
    RemoveEmailErrorAction,
    UPDATE_EMAIL,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_EMAIL_ERROR,
    UpdateEmailAction,
    UpdateEmailSuccessAction,
    UpdateEmailErrorAction,
    SimpleData,
} from './types';

export const getConfig = (): GetConfigAction => ({
    type: GET_CONFIG,
});

export const getConfigSuccess = (res: AjaxResponse<SimpleData>): GetConfigSuccessAction => ({
    type: GET_CONFIG_SUCCESS,
    payload: res.response,
});

export const getConfigError = (error: AjaxError): GetConfigErrorAction => ({
    type: GET_CONFIG_ERROR,
    error,
});

export const updateConfig = (name: string, value: string | number): UpdateConfigAction => ({
    type: UPDATE_CONFIG,
    payload: {
        name,
        value,
    },
});

export const updateConfigSuccess = (): UpdateConfigSuccessAction => ({
    type: UPDATE_CONFIG_SUCCESS,
});

export const updateConfigError = (error: AjaxError): UpdateConfigErrorAction => ({
    type: UPDATE_CONFIG_ERROR,
    error,
});

export const getEmails = (): GetEmailsAction => ({
    type: GET_EMAILS,
});

export const getEmailsSuccess = (res: AjaxResponse<Email[]>): GetEmailsSuccessAction => ({
    type: GET_EMAILS_SUCCESS,
    payload: {
        emails: res.response,
    },
});

export const getEmailsError = (error: AjaxError): GetEmailsErrorAction => ({
    type: GET_EMAILS_ERROR,
    error,
});

export const submitNewEmail = (newEmail: Email): SubmitNewEmailAction => ({
    type: SUBMIT_NEW_EMAIL,
    payload: {
        newEmail,
    },
});

export const submitNewEmailSuccess = (
    id: string,
    addTimings: TimingObj[],
    updateTimings: TimingObj[],
    deleteTimings: TimingObj[],
): SubmitNewEmailSuccessAction => ({
    type: SUBMIT_NEW_EMAIL_SUCCESS,
    payload: {
        id,
        addTimings,
        updateTimings,
        deleteTimings,
    },
});

export const submitNewEmailError = (error: AjaxError): SubmitNewEmailErrorAction => ({
    type: SUBMIT_NEW_EMAIL_ERROR,
    error,
});

export const submitNewEmailTimingSuccess = (): SubmitNewEmailTimingSuccessAction => ({
    type: SUBMIT_NEW_EMAIL_TIMING_SUCCESS,
});

export const submitNewEmailTimingError = (error: AjaxError): SubmitNewEmailTimingErrorAction => ({
    type: SUBMIT_NEW_EMAIL_TIMING_ERROR,
    error,
});

export const updateEmailTimingSuccess = (): UpdateEmailTimingSuccessAction => ({
    type: UPDATE_EMAIL_TIMING_SUCCESS,
});

export const updateEmailTimingError = (error: AjaxError): UpdateEmailTimingErrorAction => ({
    type: UPDATE_EMAIL_TIMING_ERROR,
    error,
});

export const deleteEmailTimingSuccess = (): DeleteEmailTimingSuccessAction => ({
    type: DELETE_EMAIL_TIMING_SUCCESS,
});

export const deleteEmailTimingError = (error: AjaxError): DeleteEmailTimingErrorAction => ({
    type: DELETE_EMAIL_TIMING_ERROR,
    error,
});

export const removeEmail = (id: string): RemoveEmailAction => ({
    type: REMOVE_EMAIL,
    payload: {
        id,
    },
});

export const removeEmailSuccess = (): RemoveEmailSuccessAction => ({
    type: REMOVE_EMAIL_SUCCESS,
});

export const removeEmailError = (error: AjaxError): RemoveEmailErrorAction => ({
    type: REMOVE_EMAIL_ERROR,
    error,
});

export const updateEmail = (
    email: Email,
    addTimings: TimingObj[],
    updateTimings: TimingObj[],
    deleteTimings: TimingObj[],
): UpdateEmailAction => ({
    type: UPDATE_EMAIL,
    payload: {
        email,
        addTimings,
        updateTimings,
        deleteTimings,
    },
});

export const updateEmailSuccess = (
    id: string,
    addTimings: TimingObj[],
    updateTimings: TimingObj[],
    deleteTimings: TimingObj[],
): UpdateEmailSuccessAction => ({
    type: UPDATE_EMAIL_SUCCESS,
    payload: {
        id,
        addTimings,
        updateTimings,
        deleteTimings,
    },
});

export const updateEmailError = (error: AjaxError): UpdateEmailErrorAction => ({
    type: UPDATE_EMAIL_ERROR,
    error,
});
