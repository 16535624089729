import React, { FC } from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
    Select,
    MenuItem,
    TextField,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

import {
    TimingGridProps,
    TimingTableProps,
    HeadCell,
    EnhancedTableProps,
    SendDaySelectProps,
    TextFieldProps,
    EnhancedTableToolbarProps,
} from './types';
import { timings, Timing, needsSendDayTarget, needsSendTime } from '../../redux/settings/types';

const headCells: HeadCell[] = [
    { id: 'sendDay', label: 'Ajoitus', alignRight: false },
    { id: 'sendDayTarget', label: 'Päivä', alignRight: true },
    { id: 'sendTime', label: 'Aika', alignRight: true },
];

const EnhancedTableHead: FC<EnhancedTableProps> = (props) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = (props) => {
    return (
        <Toolbar>
            {props.selected.length > 0 ? (
                <Typography color='inherit' variant='subtitle1'>
                    {props.selected.length} valittu
                </Typography>
            ) : (
                <Typography variant='h6' id='tableTitle'>
                    Ajoitukset
                </Typography>
            )}
            {props.selected.length > 0 && (
                <Tooltip title='Poista valitut ajoitukset'>
                    <IconButton
                        onClick={() => {
                            props.oldEmail
                                ? props.handleEmailRemoveTiming(props.emailIndex, props.selected)
                                : props.handleNewEmailRemoveTiming(props.selected);
                            props.setSelected([]);
                        }}
                        size='large'>
                        <Delete />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip placement='right' title='Lisää uusi ajoitus'>
                <IconButton
                    onClick={() =>
                        props.oldEmail ? props.handleEmailAddTiming(props.emailIndex) : props.handleNewEmailAddTiming()
                    }
                    size='large'>
                    <Add />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

const SendDaySelect: FC<SendDaySelectProps> = (props) => (
    <Select
        id={`new-email-timings`}
        labelId='new-email-timings-label'
        style={{ width: 300 }}
        value={props.email.timings[props.timingIndex].sendDay}
        onChange={(e) =>
            props.oldEmail
                ? props.handleEmailTimingChange(
                      props.emailIndex,
                      props.timingIndex,
                      'sendDay',
                      e.target.value as keyof typeof Timing,
                  )
                : props.handleNewEmailTimingChange(props.timingIndex, 'sendDay', e.target.value as keyof typeof Timing)
        }>
        {timings.map((timing) => (
            <MenuItem key={timing} value={timing}>
                {props.translate(timing)}
            </MenuItem>
        ))}
    </Select>
);

const SendDayTextField: FC<TextFieldProps> = (props) => (
    <TextField
        id={`new-email-send-day-target`}
        value={props.timing.sendDayTarget}
        InputLabelProps={{ shrink: true }}
        onChange={(e) =>
            props.oldEmail
                ? props.handleEmailTimingChange(props.emailIndex, props.timingIndex, 'sendDayTarget', e.target.value)
                : props.handleNewEmailTimingChange(props.timingIndex, 'sendDayTarget', e.target.value)
        }
    />
);

const SendTimeTextField: FC<TextFieldProps> = (props) => (
    <TextField
        id={`new-email-send-time`}
        type='time'
        value={props.timing.sendTime}
        InputLabelProps={{ shrink: true }}
        onChange={(e) =>
            props.oldEmail
                ? props.handleEmailTimingChange(props.emailIndex, props.timingIndex, 'sendTime', e.target.value)
                : props.handleNewEmailTimingChange(props.timingIndex, 'sendTime', e.target.value)
        }
    />
);

const TimingTable: FC<TimingTableProps> = (props) => {
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            props.setSelected(props.email.timings.map((t, index) => index));
            return;
        }
        props.setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, index: number) => {
        if (props.selected.includes(index)) {
            props.setSelected(props.selected.filter((i) => i !== index));
        } else {
            props.setSelected(props.selected.concat(index));
        }
    };

    const isSelected = (index: number) => props.selected.includes(index);

    return (
        <TableContainer>
            <Table size='small'>
                <EnhancedTableHead
                    numSelected={props.selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={props.email.timings.length}
                />
                <TableBody>
                    {props.email.timings.map((timing, timingIndex) => {
                        const isItemSelected = isSelected(timingIndex);
                        const labelId = `enhanced-table-checkbox-${timingIndex}`;

                        return (
                            <TableRow
                                hover
                                role='checkbox'
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={`${timingIndex}${timing.sendDay}`}
                                selected={isItemSelected}>
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        onClick={(event) => handleClick(event, timingIndex)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <SendDaySelect
                                        email={props.email}
                                        oldEmail={props.oldEmail}
                                        emailIndex={props.emailIndex}
                                        timingIndex={timingIndex}
                                        handleEmailTimingChange={props.handleEmailTimingChange}
                                        handleNewEmailTimingChange={props.handleNewEmailTimingChange}
                                        translate={props.translate}
                                    />
                                </TableCell>
                                <TableCell style={{ padding: '6px 16px' }} align='right'>
                                    {needsSendDayTarget.includes(timing.sendDay) && (
                                        <SendDayTextField
                                            timing={timing}
                                            oldEmail={props.oldEmail}
                                            emailIndex={props.emailIndex}
                                            timingIndex={timingIndex}
                                            handleEmailTimingChange={props.handleEmailTimingChange}
                                            handleNewEmailTimingChange={props.handleNewEmailTimingChange}
                                        />
                                    )}
                                </TableCell>
                                <TableCell style={{ padding: '6px 16px' }} align='right'>
                                    {needsSendTime.includes(timing.sendDay) && (
                                        <SendTimeTextField
                                            timing={timing}
                                            oldEmail={props.oldEmail}
                                            emailIndex={props.emailIndex}
                                            timingIndex={timingIndex}
                                            handleEmailTimingChange={props.handleEmailTimingChange}
                                            handleNewEmailTimingChange={props.handleNewEmailTimingChange}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const TimingGrid: FC<TimingGridProps> = (props) => {
    const [selected, setSelected] = React.useState<number[]>([]);

    return (
        <Paper>
            <EnhancedTableToolbar
                selected={selected}
                oldEmail={props.oldEmail}
                emailIndex={props.emailIndex}
                setSelected={setSelected}
                handleEmailAddTiming={props.handleEmailAddTiming}
                handleEmailRemoveTiming={props.handleEmailRemoveTiming}
                handleNewEmailAddTiming={props.handleNewEmailAddTiming}
                handleNewEmailRemoveTiming={props.handleNewEmailRemoveTiming}
            />
            {props.email.timings.length > 0 && (
                <TimingTable
                    selected={selected}
                    setSelected={setSelected}
                    email={props.email}
                    oldEmail={props.oldEmail}
                    emailIndex={props.emailIndex}
                    translate={props.translate}
                    handleEmailAddTiming={props.handleEmailAddTiming}
                    handleEmailRemoveTiming={props.handleEmailRemoveTiming}
                    handleEmailTimingChange={props.handleEmailTimingChange}
                    handleNewEmailAddTiming={props.handleNewEmailAddTiming}
                    handleNewEmailRemoveTiming={props.handleNewEmailRemoveTiming}
                    handleNewEmailTimingChange={props.handleNewEmailTimingChange}
                />
            )}
        </Paper>
    );
};

export default TimingGrid;
