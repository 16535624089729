import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { CallbackComponent } from 'redux-oidc';
import { User } from 'oidc-client';
import { useNavigate } from 'react-router-dom';

import userManager from '../../utils/userManager';

import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'Login';

const classes = {
    loadWrapper: `${PREFIX}-loadWrapper`,
    errorText: `${PREFIX}-errorText`,
};

const StyledCallbackComponent = styled(CallbackComponent)(({ theme }) => ({
    [`& .${classes.loadWrapper}`]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },

    [`& .${classes.errorText}`]: {
        textAlign: 'center',
    },
}));

const Login: FC = () => {
    const navigate = useNavigate();

    const [error, setError] = useState(false);

    const successCallback = (user: User) => {
        navigate((user.state && user.state.path) || '/');
    };

    const errorCallback = (error: Error) => {
        setError(true);
        console.error('login Error Callback', error); // eslint-disable-line no-console
    };

    return (
        <StyledCallbackComponent
            userManager={userManager}
            successCallback={successCallback}
            errorCallback={errorCallback}>
            <div className={classes.loadWrapper}>
                {!error ? (
                    <Fade
                        in={true}
                        style={{
                            transitionDelay: '800ms',
                        }}
                        unmountOnExit>
                        <CircularProgress color='secondary' />
                    </Fade>
                ) : (
                    <div className={classes.errorText}>
                        <a href={`/?reset=` + new Date().getTime()}>
                            Klikkaa tästä, jos sivu ei ohjaudu automaattisesti
                        </a>
                        <p>Jos sekään ei toimi, kirjautumisessa on mennyt jotain vikaan.</p>
                    </div>
                )}
            </div>
        </StyledCallbackComponent>
    );
};

export default Login;
