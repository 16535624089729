// Unstable required to not have errors about "findDOMNode" in strict mode
import { createTheme } from '@mui/material/styles';

// Some constants
const base_line_height = 1.2;
const base_font_family = ['Titillium Web', 'sans-serif'].join(',');

const theme = createTheme({
    typography: {
        fontFamily: base_font_family,
        fontSize: 16,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        h1: {
            fontSize: '2.441rem',
            lineHeight: base_line_height * 1.5,
            fontWeight: 600,
            marginBottom: `${base_line_height}rem`,
        },
        h2: {
            fontSize: '1.953rem',
            fontWeight: 600,
            marginBottom: `${base_line_height}rem`,
        },
        h3: {
            fontSize: '1.563rem',
            fontWeight: 600,
            marginBottom: `${base_line_height / 4}rem`,
        },
        h4: {
            fontSize: '1.25rem',
            fontWeight: 600,
            marginBottom: `${base_line_height / 4}rem`,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: base_line_height,
        },
        body2: {
            fontSize: '0.8rem',
            lineHeight: base_line_height,
        },
    },
    spacing: (factor: number) => `${1.2 * factor}rem`,
    shape: {
        borderRadius: 0,
    },
    transitions: {
        duration: {
            shortest: 100,
        },
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-checked.Mui-checked:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontFamily: base_font_family,
                },
                body: {
                    fontSize: '1rem',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 4,
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
    },
    mixins: {},
    palette: {
        text: {
            primary: '#252525',
            secondary: '#606060',
        },
        mode: 'light',
        background: {
            default: '#fff',
        },
        primary: {
            dark: '#ecce02',
            main: '#fde01a',
        },
        secondary: {
            dark: '#111',
            main: '#252525',
        },
        error: {
            main: '#dd311f',
        },
        warning: {
            main: '#f5d76e',
        },
        info: {
            main: '#24add5',
        },
        success: {
            main: '#26c281',
        },
        grey: {
            '400': '#dcdcdc',
            '500': '#a9a9a9',
            '600': '#767676',
            '700': '#5d5d5d',
        },
    },
});

// Change some shadows
theme.shadows[1] = '0 0 4px rgb(0 0 0 / 20%)';
theme.shadows[2] = '0 0 10px rgb(0 0 0 / 20%)';

const components = theme.components || {};

components.MuiTextField = {
    defaultProps: {
        variant: 'standard',
    },
    styleOverrides: {
        root: {
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.grey[700],
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: theme.palette.grey[500],
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.grey[600],
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.grey[700],
                    borderWidth: 1,
                },
            },
        },
    },
};
components.MuiButton = {
    defaultProps: {
        disableElevation: true,
    },
    styleOverrides: {
        root: {
            textTransform: 'none',
            fontWeight: 400,
        },
        containedPrimary: {
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        containedSecondary: {
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
    },
};
components.MuiFormLabel = {
    styleOverrides: {
        root: {
            paddingLeft: '.4rem',
            '&.Mui-focused': {
                color: theme.palette.text.primary,
            },
        },
    },
};
components.MuiInput = {
    styleOverrides: {
        input: {
            border: `1px solid ${theme.palette.grey[500]}`,
            paddingLeft: '.4rem',
            '&:hover': {
                borderColor: theme.palette.grey[600],
            },
            '&:focus': {
                borderColor: theme.palette.grey[700],
            },
        },
        underline: {
            '&::before': {
                border: 0,
            },
            '&::after': {
                border: 0,
            },
            '&:hover:not(.Mui-disabled)::before': {
                borderBottom: '0',
            },
            '&:hover::after': {
                border: 0,
            },
        },
    },
};

export default theme;
