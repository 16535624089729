import React, { FC, useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';

const ThankYou: FC = () => {
    useEffect(() => {
        document.title = 'Tarjouspyyntöportaali - kiitos tarjouspyynnöstä!';
    }, []);

    return (
        <Container maxWidth='xl'>
            <Box mt={2} mb={1}>
                <Typography variant='h1' component='h1'>
                    Kiitos tarjouspyynnön jättämisestä!
                </Typography>
                <Typography variant='body1' component='p' paragraph>
                    Olemme vastaannottaneet tarjouspyyntönne ja lähetämme teille tarjouksen viimeistään pyydettyyn
                    päivämäärään mennessä.
                </Typography>
                <Typography variant='body1' component='p' paragraph>
                    Voit palata asiakasportaaliin alla olevast linkistä.
                </Typography>
                <Box mt={2}>
                    <a href={window.config.customerPortalUrl}>Palaa asiakasportaaliin</a>
                </Box>
            </Box>
        </Container>
    );
};

export default ThankYou;
