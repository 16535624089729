import { ITextCellEditorParams } from 'ag-grid-community/dist/lib/rendering/cellEditors/textCellEditor';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';

const PREFIX = 'CustomCellEditor';

const classes = {
    cellInput: `${PREFIX}-cellInput`,
};

const StyledInput = styled('input')({
    [`&.${classes.cellInput}`]: {
        width: '100%',
        border: 'none',
        outline: 'none',
        height: '42px',
        fontSize: '14px',
        paddingLeft: '.4rem',
        paddingRight: '.4rem',
    },
});

const CustomCellEditor = forwardRef((props: ITextCellEditorParams, ref) => {
    const [value, setValue] = useState(props.value || '');
    const refInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        // focus on the input

        setTimeout(() => {
            if (refInput.current !== null) {
                refInput.current.focus();
            }
        });
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                // this simple editor doubles any value entered into the input
                return value;
            },

            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            },

            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                return false;
            },
        };
    });

    const handleChange = (v: string) => {
        // Limit input length to 256 characters
        if (v.length <= 256) {
            setValue(v);
        }
    };

    return (
        <StyledInput
            type='text'
            ref={refInput}
            className={classes.cellInput}
            value={value}
            onChange={(event) => handleChange(event.target.value)}
        />
    );
});

export default CustomCellEditor;
