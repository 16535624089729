import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Box, Typography, List, ListItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import OfferInfoModal from '../../components/offer-info-modal';
import { RootState } from '../../redux';
import { OfferData } from '../../redux/offer/types';
import { StatisticsOffer } from '../../redux/statistics/types';
import { getStatistics } from '../../redux/statistics/actions';

const PREFIX = 'Statistics';

const classes = {
    listPaper: `${PREFIX}-listPaper`,
    offer: `${PREFIX}-offer`,
    offerFirstRow: `${PREFIX}-offerFirstRow`,
    dateLabel: `${PREFIX}-dateLabel`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.listPaper}`]: {
        boxShadow: theme.shadows[1],
        padding: 0,
    },

    [`& .${classes.offer}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
        },
        '&.on-time, &.late': {
            borderRight: `5px solid ${theme.palette.success.main}`,
        },
        '&.late': {
            borderRightColor: theme.palette.error.main,
        },
    },

    [`& .${classes.offerFirstRow}`]: {
        marginBottom: '.5rem',
    },

    [`& .${classes.dateLabel}`]: {
        display: 'inline-block',
        width: '200px',
        '&.short': {
            width: '120px',
        },
    },
}));

const Statistics: FC = () => {
    const dispatch = useDispatch();

    const { offers } = useSelector((state: RootState) => state.statistics);

    useEffect(() => {
        dispatch(getStatistics());
        document.title = 'Tarjouspyyntöportaali - statistiikka';
    }, []);

    const [infoModal, setInfoModal] = useState<OfferData | null>(null);

    return (
        <Root>
            <Container maxWidth='xl'>
                <Box mt={2} mb={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <Typography variant='h1' component='h1'>
                                Statistiikka
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Typography variant='h2' component='h2'>
                                Valmiit tilaukset
                            </Typography>
                            <List className={classes.listPaper}>
                                {offers.length > 0 &&
                                    offers.map((offer: StatisticsOffer, i: number) => (
                                        <ListItem
                                            key={i}
                                            className={`${classes.offer} ${offer.onTime ? 'on-time' : 'late'}`}>
                                            <Grid container>
                                                <Grid item xs={1} className={classes.offerFirstRow}>
                                                    {offer.number ? '#' + offer.number : ''}
                                                </Grid>
                                                <Grid item xs={3} className={classes.offerFirstRow}>
                                                    {offer.customer}
                                                </Grid>
                                                <Grid item xs={4} className={classes.offerFirstRow}>
                                                    <span className={`${classes.dateLabel} short`}>Jätetty:</span>
                                                    {offer.submitted ? new Date(offer.submitted).toLocaleString() : '-'}
                                                </Grid>
                                                <Grid item xs={4} className={classes.offerFirstRow}>
                                                    <span className={`${classes.dateLabel} short`}>Käsittelijä:</span>
                                                    {offer.handler}
                                                </Grid>
                                                <Grid item xs={1} />
                                                <Grid item xs={3}>
                                                    {offer.reference}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <span className={`${classes.dateLabel} short`}>Lähetetty:</span>
                                                    {offer.done ? new Date(offer.done).toLocaleString() : '-'}
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                            </Grid>
                                        </ListItem>
                                    ))}
                                {offers.length === 0 && <Box p={1}>Ei valmiita tarjouspyyntöjä</Box>}
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <OfferInfoModal open={Boolean(infoModal)} offer={infoModal} handleClose={() => setInfoModal(null)} />
        </Root>
    );
};

export default Statistics;
