import { combineEpics, Epic } from 'redux-observable';
import { filter, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import { api } from '../../utils/api';

import {
    GET_USER,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    Role,
    User,
    USER_FOUND,
    UserActionTypes,
    UserState,
} from './types';
import { getUserError, getUserSuccess } from './actions';
import { RootState } from '..';

const initialState: UserState = {
    id: undefined,
    name: undefined,
    email: undefined,
    role: Role.NONE,
    loading: true,
};

const offer = (state = initialState, action: UserActionTypes): UserState => {
    switch (action.type) {
        case GET_USER_SUCCESS:
            return {
                ...state,
                id: action.payload.id,
                name: action.payload.name,
                email: action.payload.email,
                role: action.payload.role,
                loading: false,
            };
        case GET_USER_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default offer;

export const getUserEpic: Epic<UserActionTypes, UserActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType([GET_USER, USER_FOUND])),
        mergeMap(() =>
            api.get<User>('/user/me').pipe(
                map((res) => getUserSuccess(res)),
                catchError((error) => of(getUserError(error))),
            ),
        ),
    );

export const epics = combineEpics(getUserEpic);
