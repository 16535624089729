import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

declare global {
    interface Navigator {
        userLanguage?: string;
        browserLanguage?: string;
        languages: readonly string[];
        language: string;
    }

    interface Window {
        // __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
        config: {
            issuer: string;
            clientId: string;
            apiUrl: string;
            logoutRedirectUrl: string;
            customerPortalUrl: string;
        };
    }
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
