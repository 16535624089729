import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { OfferData } from '../../redux/offer/types';

const PREFIX = 'Offer-LoadSavedOrderModal';

const classes = {
    select: `${PREFIX}-select`,
    selectFull: `${PREFIX}-selectFull`,
    selectItemDual: `${PREFIX}-selectItemDual`,
};

const StylesBox = styled(Box)({
    [`& .${classes.select}`]: {
        width: 350,
    },

    [`& .${classes.selectFull}`]: {
        width: '100%',
    },

    [`& .${classes.selectItemDual}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '100%',
    },
});

const LoadSavedOrderModal = ({
    handleLoadConfirmationModalAccept,
    handleLoadConfirmationModalDecline,
    savedOrders,
    selectedOfferToLoad,
    setShowLoadConfirmationModal,
    setSelectedOfferToLoad,
    showLoadConfirmationModal,
}: Props) => {
    return (
        <Dialog
            open={showLoadConfirmationModal}
            onClose={() => setShowLoadConfirmationModal(false)}
            aria-labelledby='Tallennetun tilauksen lataaminen'
            aria-describedby='load-confirmation-modal'>
            <DialogTitle>Haluatko ladata tallennetun tarjouspyynnön?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Sinulla on aikasemmin aloitettuja tarjouspyyntöjä kesken. Haluatko jatkaa jotain niistä?
                </DialogContentText>
                <StylesBox my={1}>
                    <InputLabel id='saved-order-select-label' shrink={true}>
                        Tallennetut tilaukset
                    </InputLabel>
                    <Select
                        id='saved-order-select'
                        labelId='saved-order-select-label'
                        className={`${classes.select} ${classes.selectFull}`}
                        value={selectedOfferToLoad}
                        onChange={(e) => setSelectedOfferToLoad(e.target.value as string)}>
                        {savedOrders?.map((o) => (
                            <MenuItem key={o.id} value={o.id}>
                                <div className={classes.selectItemDual}>
                                    <div>{o.updated ? new Date(o.updated).toLocaleString() : '-'}</div>
                                    <div>[{o.reference || '-'}]</div>
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </StylesBox>
                <DialogActions>
                    <Button variant='contained' color='secondary' onClick={handleLoadConfirmationModalDecline}>
                        En
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={!selectedOfferToLoad}
                        onClick={handleLoadConfirmationModalAccept}>
                        Kyllä
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

interface Props {
    handleLoadConfirmationModalAccept: () => void;
    handleLoadConfirmationModalDecline: () => void;
    savedOrders: OfferData[] | null;
    selectedOfferToLoad: string;
    setShowLoadConfirmationModal: (value: boolean) => void;
    setSelectedOfferToLoad: (value: string) => void;
    showLoadConfirmationModal: boolean;
}

export default LoadSavedOrderModal;
