import { combineEpics, Epic } from 'redux-observable';
import { EmptyObject } from 'redux';
import { filter, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import FileSaver from 'file-saver';
import { isOfType } from 'typesafe-actions';

import { api } from '../../utils/api';
import {
    AUTO_SAVE,
    AUTO_SAVE_SUCCESS,
    Customer,
    DOWNLOAD_OFFER_FILE,
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_SAVED_ORDERS,
    GET_SAVED_ORDERS_SUCCESS,
    MAKE_NEW_OFFER,
    MAKE_NEW_OFFER_SUCCESS,
    OfferActionTypes,
    OfferData,
    OfferState,
    REMOVE_FILE,
    SAVE_FILE,
    SAVE_FILE_SUCCESS,
    SUBMIT_OFFER,
    SUBMIT_OFFER_SUCCESS,
    USE_SAVED_ORDER_ID,
} from './types';
import { RootState } from '..';
import { IdWrapper } from '../types';
import {
    autoSaveError,
    autoSaveSuccess,
    downloadOfferFileError,
    downloadOfferFileSuccess,
    getCustomersError,
    getCustomersSuccess,
    getSavedOrderError,
    getSavedOrderSuccess,
    makeNewOfferError,
    makeNewOfferSuccess,
    removeFileError,
    removeFileSuccess,
    saveFileError,
    saveFileSuccess,
    submitOfferError,
    submitOfferSuccess,
} from './actions';

const initialState: OfferState = {
    id: null,
    savedOrders: null,
    fileIdReplace: undefined,
    customers: [],
    newCustomerId: undefined,
};

const offer = (state = initialState, action: OfferActionTypes): OfferState => {
    switch (action.type) {
        case GET_CUSTOMERS_SUCCESS:
            return { ...state, customers: action.payload.customers };
        case MAKE_NEW_OFFER_SUCCESS:
            return { ...state, id: action.payload.id };
        case GET_SAVED_ORDERS_SUCCESS:
            return {
                ...state,
                savedOrders: action.payload.savedOrders.filter(
                    (o) => (o.customer && o.customer.customUser) || o.reference || o.message || o.offerTable,
                ),
            };
        case USE_SAVED_ORDER_ID:
            return { ...state, id: action.payload.id };
        case AUTO_SAVE_SUCCESS:
            return { ...state, newCustomerId: action.payload.newCustomerId };
        case SAVE_FILE_SUCCESS:
            return { ...state, fileIdReplace: { tmpId: action.payload.tmpId, id: action.payload.id } };
        case SUBMIT_OFFER_SUCCESS:
            return initialState;
        default:
            return state;
    }
};

export default offer;

export const getCustomersEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(GET_CUSTOMERS)),
        mergeMap(() =>
            api.get<Customer[]>('/customer', { filter: { q: '' }, sort: 'customUser,ASC' }).pipe(
                map((res) => getCustomersSuccess(res)),
                catchError((error) => of(getCustomersError(error))),
            ),
        ),
    );

export const makeNewOfferEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(MAKE_NEW_OFFER)),
        mergeMap(() =>
            api.post<OfferData>('/offer', {}, {}).pipe(
                map((res) => makeNewOfferSuccess(res)),
                catchError((error) => of(makeNewOfferError(error))),
            ),
        ),
    );

export const getSavedOrderEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(GET_SAVED_ORDERS)),
        mergeMap(() =>
            api
                .get<OfferData[]>('/offer', {
                    filter: {
                        status: 'SAVED',
                        user: state$.value.user.id || '',
                    },
                    sort: 'updated,DESC',
                })
                .pipe(
                    map((res) => getSavedOrderSuccess(res)),
                    catchError((error) => of(getSavedOrderError(error))),
                ),
        ),
    );

export const autoSaveEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(AUTO_SAVE)),
        map((action) => ({ ...action.payload.data, id: state$.value.offer.id || '' })),
        mergeMap((requestBody) =>
            api.put<OfferData>(`/offer/${state$.value.offer.id}`, requestBody).pipe(
                map((res) => autoSaveSuccess(res)),
                catchError((error) => of(autoSaveError(error))),
            ),
        ),
    );

export const saveFileEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(SAVE_FILE)),
        mergeMap((action) =>
            from(readFile(action.payload.file)).pipe(
                mergeMap((fileAsDataUrl) =>
                    api
                        .post<IdWrapper<string>>(`/offer/${state$.value.offer.id}/file`, {
                            data: fileAsDataUrl,
                            name: action.payload.name,
                        })
                        .pipe(
                            map((res) => saveFileSuccess(action.payload.tmpId, res)),
                            catchError((error) => of(saveFileError(error))),
                        ),
                ),
            ),
        ),
    );

export const removeFileEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(REMOVE_FILE)),
        mergeMap((action) =>
            api.delete(`/offer/${state$.value.offer.id}/file/${action.payload.id}`).pipe(
                map(() => removeFileSuccess()),
                catchError((error) => of(removeFileError(error))),
            ),
        ),
    );

export const submitOfferEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(SUBMIT_OFFER)),
        mergeMap(() =>
            api.patch<EmptyObject>(`/offer/${state$.value.offer.id}/send`, {}).pipe(
                map((res) => submitOfferSuccess(res)),
                catchError((error) => of(submitOfferError(error))),
            ),
        ),
    );

export const downloadOfferFileEpic: Epic<OfferActionTypes, OfferActionTypes, RootState> = (action$) =>
    action$.pipe(
        filter(isOfType(DOWNLOAD_OFFER_FILE)),
        mergeMap((action) =>
            api.blob(`/offer/${action.payload.offerId}/file/${action.payload.fileId}`).pipe(
                map(
                    (res) => new Blob([res.response], { type: res.xhr.getResponseHeader('Content-Type') || undefined }),
                ),
                map((blob) => FileSaver.saveAs(blob, action.payload.name)),
                map(() => downloadOfferFileSuccess()),
                catchError((error) => of(downloadOfferFileError(error))),
            ),
        ),
    );

export const epics = combineEpics(
    makeNewOfferEpic,
    getSavedOrderEpic,
    autoSaveEpic,
    saveFileEpic,
    removeFileEpic,
    submitOfferEpic,
    getCustomersEpic,
    downloadOfferFileEpic,
);

// HELPERS

const readFile = (file: File) =>
    new Observable<string>((subscriber) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // Required to match types of reader.result and subscriber.next
            if (!reader.result) {
                throw Error('File empty!');
            }
            subscriber.next(reader.result.toString());
            subscriber.complete();
        };
        reader.onerror = () => subscriber.error(reader.error);
        return () => reader.abort(); // cancel function in case you unsubscribe from the obs
    });
