const getUtcOffset = (timezone: string) => {
    const date = new Date();
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const localDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
    return (localDate.getTime() - utcDate.getTime()) / 36e5;
};

const utcToLocal = (time: string, timezone = 'Europe/Helsinki'): string => {
    if (time === null) return '';
    return `${String(Math.min(23, Math.max(0, parseInt(time.split(':')[0]) + getUtcOffset(timezone)))).padStart(
        2,
        '0',
    )}:${time.split(':')[1]}`;
};

const localToUtc = (time: string, timezone = 'Europe/Helsinki'): string => {
    return `${String(Math.min(23, Math.max(0, parseInt(time.split(':')[0]) - getUtcOffset(timezone)))).padStart(
        2,
        '0',
    )}:${time.split(':')[1]}`;
};

export { localToUtc, utcToLocal };
