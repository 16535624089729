import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

export interface SimpleData {
    deadlineWishDefaultAfterDays?: number;
    offerRequestInfo?: string;
    tawkToLink?: string;
    tawkToApiKey?: string;
    tawkToHash?: string;
    emailSender?: string;
    infoPdfName?: string;
}

export enum OfferRequestStatus {
    SAVED = 'SAVED',
    SENT = 'SENT',
    ASSIGNED = 'ASSIGNED',
    UNASSIGNED = 'UNASSIGNED',
    HANDLING = 'HANDLING',
    DONE = 'DONE',
}

export enum AssignmentStatus {
    WAITING = 'WAITING',
    ACCEPT = 'ACCEPT',
    DECLINE = 'DECLINE',
}

export const offerRequestStatus = [
    OfferRequestStatus.SAVED,
    OfferRequestStatus.SENT,
    OfferRequestStatus.ASSIGNED,
    OfferRequestStatus.UNASSIGNED,
    OfferRequestStatus.HANDLING,
    OfferRequestStatus.DONE,
];

export const assignmentStatus = [AssignmentStatus.WAITING, AssignmentStatus.ACCEPT, AssignmentStatus.DECLINE];

export enum Timing {
    INSTANT = 'INSTANT',
    TOMORROW = 'TOMORROW',
    DAYS_AFTER = 'DAYS_AFTER',
    DAYS_BEFORE_DEADLINE_WISH = 'DAYS_BEFORE_DEADLINE_WISH',
    NEXT_WEEKDAY = 'NEXT_WEEKDAY',
    NEXT_TARGET_DAY = 'NEXT_TARGET_DAY',
    NEXT_WEEK_TARGET_DAY = 'NEXT_WEEK_TARGET_DAY',
    NEXT_MONTH = 'NEXT_MONTH',
}

export const timings = [
    Timing.INSTANT,
    Timing.TOMORROW,
    Timing.DAYS_AFTER,
    Timing.DAYS_BEFORE_DEADLINE_WISH,
    Timing.NEXT_WEEKDAY,
    Timing.NEXT_TARGET_DAY,
    Timing.NEXT_WEEK_TARGET_DAY,
    Timing.NEXT_MONTH,
];

export const needsSendDayTarget = [
    Timing.DAYS_AFTER,
    Timing.DAYS_BEFORE_DEADLINE_WISH,
    Timing.NEXT_TARGET_DAY,
    Timing.NEXT_WEEK_TARGET_DAY,
    Timing.NEXT_MONTH,
];

export const needsSendTime = [
    Timing.TOMORROW,
    Timing.DAYS_AFTER,
    Timing.DAYS_BEFORE_DEADLINE_WISH,
    Timing.NEXT_WEEKDAY,
    Timing.NEXT_TARGET_DAY,
    Timing.NEXT_WEEK_TARGET_DAY,
    Timing.NEXT_MONTH,
];

export interface TimingObj {
    id: number;
    sendDay: Timing;
    sendDayTarget: number;
    sendTime: string;
}

export interface Email {
    id?: string;
    recipients: string[];
    subject: string;
    message: string;
    status: OfferRequestStatus;
    includeInfoPdf: boolean;
    includeFiles: boolean;
    timings: TimingObj[];
}

// State
export interface SettingsState {
    simpleData: SimpleData;
    emails?: Email[];
}

export const GET_CONFIG = 'settings:GET_CONFIG';
export const GET_CONFIG_SUCCESS = 'settings:GET_CONFIG_SUCCESS';
export const GET_CONFIG_ERROR = 'settings:GET_CONFIG_ERROR';
export const UPDATE_CONFIG = 'settings:UPDATE_CONFIG';
export const UPDATE_CONFIG_SUCCESS = 'settings:UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_ERROR = 'settings:UPDATE_CONFIG_ERROR';
export const GET_EMAILS = 'settings:GET_EMAILS';
export const GET_EMAILS_SUCCESS = 'settings:GET_EMAILS_SUCCESS';
export const GET_EMAILS_ERROR = 'settings:GET_EMAILS_ERROR';
export const SUBMIT_NEW_EMAIL = 'settings:SUBMIT_NEW_EMAIL';
export const SUBMIT_NEW_EMAIL_SUCCESS = 'settings:SUBMIT_NEW_EMAIL_SUCCESS';
export const SUBMIT_NEW_EMAIL_ERROR = 'settings:SUBMIT_NEW_EMAIL_ERROR';
export const SUBMIT_NEW_EMAIL_TIMING_SUCCESS = 'settings:SUBMIT_NEW_EMAIL_TIMING_SUCCESS';
export const SUBMIT_NEW_EMAIL_TIMING_ERROR = 'settings:SUBMIT_NEW_EMAIL_TIMING_ERROR';
export const UPDATE_EMAIL_TIMING_SUCCESS = 'settings:DELETE_EMAIL_TIMING_SUCCESS';
export const UPDATE_EMAIL_TIMING_ERROR = 'settings:DELETE_EMAIL_TIMING_ERROR';
export const DELETE_EMAIL_TIMING_SUCCESS = 'settings:DELETE_EMAIL_TIMING_SUCCESS';
export const DELETE_EMAIL_TIMING_ERROR = 'settings:DELETE_EMAIL_TIMING_ERROR';
export const REMOVE_EMAIL = 'settings:REMOVE_EMAIL';
export const REMOVE_EMAIL_SUCCESS = 'settings:REMOVE_EMAIL_SUCCESS';
export const REMOVE_EMAIL_ERROR = 'settings:REMOVE_EMAIL_ERROR';
export const UPDATE_EMAIL = 'settings:UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = 'settings:UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_ERROR = 'settings:UPDATE_EMAIL_ERROR';

export interface GetConfigAction extends Action {
    type: typeof GET_CONFIG;
}

export interface GetConfigSuccessAction extends Action {
    type: typeof GET_CONFIG_SUCCESS;
    payload: SimpleData;
}

export interface GetConfigErrorAction extends Action {
    type: typeof GET_CONFIG_ERROR;
    error: AjaxError;
}

export interface UpdateConfigAction extends Action {
    type: typeof UPDATE_CONFIG;
    payload: {
        name: string;
        value: string | number;
    };
}

export interface UpdateConfigSuccessAction extends Action {
    type: typeof UPDATE_CONFIG_SUCCESS;
}

export interface UpdateConfigErrorAction extends Action {
    type: typeof UPDATE_CONFIG_ERROR;
    error: AjaxError;
}

export interface GetEmailsAction extends Action {
    type: typeof GET_EMAILS;
}

export interface GetEmailsSuccessAction extends Action {
    type: typeof GET_EMAILS_SUCCESS;
    payload: {
        emails: Email[];
    };
}

export interface GetEmailsErrorAction extends Action {
    type: typeof GET_EMAILS_ERROR;
    error: AjaxError;
}

export interface SubmitNewEmailAction extends Action {
    type: typeof SUBMIT_NEW_EMAIL;
    payload: {
        newEmail: Email;
    };
}

export interface SubmitNewEmailSuccessAction extends Action {
    type: typeof SUBMIT_NEW_EMAIL_SUCCESS;
    payload: {
        id: string;
        addTimings: TimingObj[];
        updateTimings: TimingObj[];
        deleteTimings: TimingObj[];
    };
}

export interface SubmitNewEmailErrorAction extends Action {
    type: typeof SUBMIT_NEW_EMAIL_ERROR;
    error: AjaxError;
}

export interface SubmitNewEmailTimingSuccessAction extends Action {
    type: typeof SUBMIT_NEW_EMAIL_TIMING_SUCCESS;
}

export interface SubmitNewEmailTimingErrorAction extends Action {
    type: typeof SUBMIT_NEW_EMAIL_TIMING_ERROR;
    error: AjaxError;
}

export interface UpdateEmailTimingSuccessAction extends Action {
    type: typeof UPDATE_EMAIL_TIMING_SUCCESS;
}

export interface UpdateEmailTimingErrorAction extends Action {
    type: typeof UPDATE_EMAIL_TIMING_ERROR;
    error: AjaxError;
}

export interface DeleteEmailTimingSuccessAction extends Action {
    type: typeof DELETE_EMAIL_TIMING_SUCCESS;
}

export interface DeleteEmailTimingErrorAction extends Action {
    type: typeof DELETE_EMAIL_TIMING_ERROR;
    error: AjaxError;
}

export interface RemoveEmailAction extends Action {
    type: typeof REMOVE_EMAIL;
    payload: {
        id: string;
    };
}

export interface RemoveEmailSuccessAction extends Action {
    type: typeof REMOVE_EMAIL_SUCCESS;
}

export interface RemoveEmailErrorAction extends Action {
    type: typeof REMOVE_EMAIL_ERROR;
    error: AjaxError;
}

export interface UpdateEmailAction extends Action {
    type: typeof UPDATE_EMAIL;
    payload: {
        email: Email;
        addTimings: TimingObj[];
        updateTimings: TimingObj[];
        deleteTimings: TimingObj[];
    };
}

export interface UpdateEmailSuccessAction extends Action {
    type: typeof UPDATE_EMAIL_SUCCESS;
    payload: {
        id: string;
        addTimings: TimingObj[];
        updateTimings: TimingObj[];
        deleteTimings: TimingObj[];
    };
}

export interface UpdateEmailErrorAction extends Action {
    type: typeof UPDATE_EMAIL_ERROR;
    error: AjaxError;
}

export type SettingsActionTypes =
    | GetConfigAction
    | GetConfigSuccessAction
    | GetConfigErrorAction
    | UpdateConfigAction
    | UpdateConfigSuccessAction
    | UpdateConfigErrorAction
    | GetEmailsAction
    | GetEmailsSuccessAction
    | GetEmailsErrorAction
    | SubmitNewEmailAction
    | SubmitNewEmailSuccessAction
    | SubmitNewEmailErrorAction
    | SubmitNewEmailTimingSuccessAction
    | SubmitNewEmailTimingErrorAction
    | UpdateEmailTimingSuccessAction
    | UpdateEmailTimingErrorAction
    | DeleteEmailTimingSuccessAction
    | DeleteEmailTimingErrorAction
    | RemoveEmailAction
    | RemoveEmailSuccessAction
    | RemoveEmailErrorAction
    | UpdateEmailAction
    | UpdateEmailSuccessAction
    | UpdateEmailErrorAction;
