import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';
import { OfferData } from '../offer/types';
import { Pages } from '../my-offers/types';

export interface User {
    id: string;
    name: string;
    email: string;
    role: string;
}

// State
export interface AssignState {
    offers?: OfferData[];
    offersPage: number;
    offersSize: number;
    assigned: Record<string, Pages>;
    assignedTotalCounts: Record<string, number>;
    staff?: User[];
}

// Action types
export const GET_UNASSIGNED_OFFERS = 'assign:GET_UNASSIGNED_OFFERS';
export const GET_UNASSIGNED_OFFERS_SUCCESS = 'assign:GET_UNASSIGNED_OFFERS_SUCCESS';
export const GET_UNASSIGNED_OFFERS_ERROR = 'assign:GET_UNASSIGNED_OFFERS_ERROR';
export const CHANGE_OFFERS_PAGE = 'assign:CHANGE_OFFERS_PAGE';
export const GET_ASSIGNED_OFFERS = 'assign:GET_ASSIGNED_OFFERS';
export const GET_ASSIGNED_OFFERS_SUCCESS = 'assign:GET_ASSIGNED_OFFERS_SUCCESS';
export const GET_ASSIGNED_OFFERS_ERROR = 'assign:GET_ASSIGNED_OFFERS_ERROR';
export const GET_STAFF = 'assign:GET_STAFF';
export const GET_STAFF_SUCCESS = 'assign:GET_STAFF_SUCCESS';
export const GET_STAFF_ERROR = 'assign:GET_STAFF_ERROR';
export const ASSIGN_OFFER = 'assign:ASSIGN_OFFER';
export const ASSIGN_OFFER_SUCCESS = 'assign:ASSIGN_OFFER_SUCCESS';
export const ASSIGN_OFFER_ERROR = 'assign:ASSIGN_OFFER_ERROR';

// Actions
export interface GetUnassignedOffersAction extends Action {
    type: typeof GET_UNASSIGNED_OFFERS;
}

export interface GetUnassignedOffersSuccessAction extends Action {
    type: typeof GET_UNASSIGNED_OFFERS_SUCCESS;
    payload: {
        offers: OfferData[];
        size: number;
    };
}

export interface GetUnassignedOffersErrorAction extends Action {
    type: typeof GET_UNASSIGNED_OFFERS_ERROR;
    error: AjaxError;
}

export interface changeOffersPageAction extends Action {
    type: typeof CHANGE_OFFERS_PAGE;
    payload: {
        page: number;
    };
}

export interface GetAssignedOffersAction extends Action {
    type: typeof GET_ASSIGNED_OFFERS;
    payload: {
        person: string;
        page: number;
    };
}

export interface GetAssignedOffersSuccessAction extends Action {
    type: typeof GET_ASSIGNED_OFFERS_SUCCESS;
    payload: {
        offers: OfferData[];
        person: string;
        page: number;
        size: number;
    };
}

export interface GetAssignedOffersErrorAction extends Action {
    type: typeof GET_ASSIGNED_OFFERS_ERROR;
    error: AjaxError;
}

export interface GetStaffAction extends Action {
    type: typeof GET_STAFF;
}

export interface GetStaffSuccessAction extends Action {
    type: typeof GET_STAFF_SUCCESS;
    payload: {
        staff: User[];
    };
}
export interface GetStaffErrorAction extends Action {
    type: typeof GET_STAFF_ERROR;
    error: AjaxError;
}

export interface AssignOfferAction extends Action {
    type: typeof ASSIGN_OFFER;
    payload: {
        offerId: string;
        staffId: string;
    };
}

export interface AssignOfferSuccessAction extends Action {
    type: typeof ASSIGN_OFFER_SUCCESS;
}
export interface AssignOfferErrorAction extends Action {
    type: typeof ASSIGN_OFFER_ERROR;
    error: AjaxError;
}

export type AssignActionTypes =
    | GetUnassignedOffersAction
    | GetUnassignedOffersSuccessAction
    | GetUnassignedOffersErrorAction
    | changeOffersPageAction
    | GetAssignedOffersAction
    | GetAssignedOffersSuccessAction
    | GetAssignedOffersErrorAction
    | GetStaffAction
    | GetStaffSuccessAction
    | GetStaffErrorAction
    | AssignOfferAction
    | AssignOfferSuccessAction
    | AssignOfferErrorAction;
