import { ReactElement, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { RootState } from '../../redux';
import Login from '../../views/login';

import userManager from '../../utils/userManager';
import { AuthWrapperProps } from './types';

import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'AuthWrapper';

const classes = {
    loadWrapper: `${PREFIX}-loadWrapper`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.loadWrapper}`]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
}));

const AuthWrapper = ({ children, onSuccess }: AuthWrapperProps): ReactElement | null => {
    const location = useLocation();

    const { role, loading: loadingUser } = useSelector((state: RootState) => state.user);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (location.pathname === '/login') return;
        userManager.getUser().then((user) => {
            if (!user || user.expired) {
                userManager.signinRedirect({
                    data: {
                        path: location.pathname,
                    },
                });
            } else if (!isAuthenticated) {
                onSuccess();
                setIsAuthenticated(true);
            }
        });
    });

    // Login
    if (location.pathname === '/login') {
        return <Login />;
    }
    // Waiting for authentication
    else if (!isAuthenticated) {
        return (
            <Root className={classes.loadWrapper}>
                <Fade
                    in={true}
                    style={{
                        transitionDelay: '800ms',
                    }}
                    unmountOnExit>
                    <CircularProgress color='secondary' />
                </Fade>
            </Root>
        );
    }
    // Waiting for user
    else if (loadingUser) {
        return (
            <Root className={classes.loadWrapper}>
                <CircularProgress color='secondary' />
            </Root>
        );
    }
    // No access
    else if (role === 'NONE') {
        return (
            <Root className={classes.loadWrapper}>
                <div>
                    <p>Sinulla ei ole oikeuksia tälle sivulle.</p>
                    <a href={window.config.customerPortalUrl}>Voit palata asiakasportaaliin tästä</a>.
                </div>
            </Root>
        );
    }
    // All good
    else {
        return children;
    }
};

export default AuthWrapper;
