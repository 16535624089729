import { Action } from 'redux';
import { AjaxError } from 'rxjs/ajax';

export enum Role {
    ADMIN = 'ADMIN',
    STAFF = 'STAFF',
    CUSTOMER = 'CUSTOMER',
    NONE = 'NONE',
}

export interface User {
    id: string;
    name: string;
    email: string;
    role: keyof typeof Role;
}

// State
export interface UserState {
    id?: string;
    name?: string;
    email?: string;
    role: keyof typeof Role;
    loading: boolean;
}

// Action types
export const GET_USER = 'user:GET_USER';
export const GET_USER_SUCCESS = 'user:GET_USER_SUCCESS';
export const GET_USER_ERROR = 'user:GET_USER_ERROR';

export const USER_FOUND = 'redux-oidc/USER_FOUND';

// Actions
export interface GetUserAction extends Action {
    type: typeof GET_USER;
}

export interface GetUserSuccessAction extends Action {
    type: typeof GET_USER_SUCCESS;
    payload: User;
}

export interface GetUserErrorAction extends Action {
    type: typeof GET_USER_ERROR;
    error: AjaxError;
}

export interface UserFoundAction extends Action {
    type: typeof USER_FOUND;
    payload: Record<string, unknown>;
}

export type UserActionTypes = GetUserAction | GetUserSuccessAction | GetUserErrorAction;
