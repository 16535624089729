import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    List,
    ListItem,
    IconButton,
    Icon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useSelector, useDispatch } from 'react-redux';

import OfferInfoModal from '../../components/offer-info-modal';
import { RootState } from '../../redux';
import { OfferData } from '../../redux/offer/types';
import { changePage, getMyOffers, answerProposal, completeOffer } from '../../redux/my-offers/actions';

const PREFIX = 'MyOffers';

const classes = {
    listPaper: `${PREFIX}-listPaper`,
    offer: `${PREFIX}-offer`,
    offerFirstRow: `${PREFIX}-offerFirstRow`,
    dateLabel: `${PREFIX}-dateLabel`,
    decisionButton: `${PREFIX}-decisionButton`,
    infoButtonWrapper: `${PREFIX}-infoButtonWrapper`,
    infoButton: `${PREFIX}-infoButton`,
    showMore: `${PREFIX}-showMore`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.listPaper}`]: {
        boxShadow: theme.shadows[1],
        padding: 0,
    },

    [`& .${classes.offer}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
        },
        '&.on-time, &.late': {
            borderRight: `5px solid ${theme.palette.success.main}`,
        },
        '&.late': {
            borderRightColor: theme.palette.error.main,
        },
    },

    [`& .${classes.offerFirstRow}`]: {
        marginBottom: '.5rem',
    },

    [`& .${classes.dateLabel}`]: {
        display: 'inline-block',
        width: '200px',
        '&.short': {
            width: '120px',
        },
    },

    [`& .${classes.decisionButton}`]: {
        width: '96px',
        padding: '0 16px',
        '&.MuiButton-containedPrimary': {
            marginBottom: '.5rem',
        },
    },

    [`& .${classes.infoButtonWrapper}`]: {
        textAlign: 'right',
    },

    [`& .${classes.infoButton}`]: {
        padding: 0,
        transition: `opacity ${theme.transitions.duration.shortest}ms`,
        '&:hover': {
            opacity: 0.8,
        },
        marginRight: theme.spacing(1),
    },

    [`& .${classes.showMore}`]: {
        color: theme.palette.info.main,
    },
}));

const MyOffers: FC = () => {
    const dispatch = useDispatch();

    const {
        proposed: proposedOffers,
        proposedPage,
        proposedSize,
        accepted: acceptedOffers,
        acceptedPage,
        acceptedSize,
        done: doneOffers,
        donePage,
        doneSize,
    } = useSelector((state: RootState) => state.myOffers);
    useEffect(() => {
        dispatch(getMyOffers());
        document.title = 'Tarjouspyyntöportaali - käsittely';
    }, []);

    const [infoModal, setInfoModal] = useState<OfferData | null>(null);
    const [declineDialog, setDeclineDialog] = useState<{ show: boolean; id?: string; reason: string }>({
        show: false,
        reason: '',
    });
    const [acceptDialog, setAcceptDialog] = useState<{ show: boolean; id?: string }>({
        show: false,
    });
    const [completeDialog, setCompleteDialog] = useState<{ show: boolean; id?: string }>({
        show: false,
    });

    const handleShowMore = (type: string) => {
        const page = type === 'accepted' ? acceptedPage : proposedPage;
        handlePagination(type, page + 1);
    };

    const handleAcceptProposedOffer = () => {
        dispatch(answerProposal(acceptDialog.id || '-', '', 'accept'));
        setAcceptDialog({ show: false, id: undefined });
    };

    const handleRejectProposedOffer = () => {
        dispatch(answerProposal(declineDialog.id ? declineDialog.id : '-', declineDialog.reason, 'decline'));
        setDeclineDialog({ show: false, id: undefined, reason: '' });
    };

    const handleCompleteProposedOffer = () => {
        dispatch(completeOffer(completeDialog.id ? completeDialog.id : '-'));
        setCompleteDialog({ show: false, id: undefined });
    };

    const handlePagination = (type: string, page: number) => {
        dispatch(changePage(page, type));
        dispatch(getMyOffers());
    };

    return (
        <Root>
            <Container maxWidth='xl'>
                <Box my={2}>
                    <Grid container spacing={2} alignItems='center' justifyContent='center'>
                        <Grid item xs={9}>
                            <Typography variant='h2' component='h1' gutterBottom>
                                Ehdotetut
                            </Typography>
                            <List className={classes.listPaper}>
                                {proposedOffers['0'] &&
                                    proposedOffers['0'].length > 0 &&
                                    Object.keys(proposedOffers)
                                        .map((p) => proposedOffers[p])
                                        .flat()
                                        .map((offer: OfferData) => (
                                            <ListItem key={offer.id} className={classes.offer}>
                                                <Grid container>
                                                    <Grid item xs={1} className={classes.offerFirstRow}>
                                                        {offer.number ? '#' + offer.number : ''}
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.offerFirstRow}>
                                                        {offer.customer ? offer.customer.name : '-'}
                                                    </Grid>
                                                    <Grid item xs={5} className={classes.offerFirstRow}>
                                                        <span className={classes.dateLabel}>Jätetty:</span>
                                                        {offer.submissionTime
                                                            ? new Date(offer.submissionTime).toLocaleString()
                                                            : '-'}
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            className={classes.decisionButton}
                                                            onClick={() =>
                                                                setAcceptDialog({
                                                                    show: true,
                                                                    id: offer.id,
                                                                })
                                                            }>
                                                            Hyväksy
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={1} className={classes.infoButtonWrapper}>
                                                        <IconButton
                                                            className={classes.infoButton}
                                                            edge='end'
                                                            aria-label='info'
                                                            onClick={() => setInfoModal(offer)}
                                                            size='large'>
                                                            <Icon>info</Icon>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={1} />
                                                    <Grid item xs={4}>
                                                        {offer.reference || '-'}
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <span className={classes.dateLabel}>Halutaan viimeistään:</span>
                                                        {offer.fast
                                                            ? 'PIKA'
                                                            : offer.deadlineWish
                                                            ? new Date(offer.deadlineWish).toLocaleDateString()
                                                            : '-'}
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button
                                                            variant='contained'
                                                            color='secondary'
                                                            className={classes.decisionButton}
                                                            onClick={() =>
                                                                setDeclineDialog({
                                                                    show: true,
                                                                    id: offer.id,
                                                                    reason: '',
                                                                })
                                                            }>
                                                            Hylkää
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        ))}
                                {(proposedPage + 1) * 10 < proposedSize && (
                                    <Grid container justifyContent='center'>
                                        <Grid item>
                                            <Box my={0.25}>
                                                <Button
                                                    variant='text'
                                                    color='secondary'
                                                    className={classes.showMore}
                                                    onClick={() => handleShowMore('proposed')}>
                                                    Näytä lisää
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                                {(!proposedOffers['0'] || proposedOffers['0']?.length === 0) && (
                                    <Box p={1}>Ei kuormitettuja tarjouspyyntöjä</Box>
                                )}
                                <Dialog
                                    open={acceptDialog.show}
                                    onClose={() => setAcceptDialog({ show: false, id: undefined })}>
                                    <DialogContent>
                                        <DialogContentText>
                                            Oletko varma että haluat hyväksyä kuormituksen?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant='contained' color='primary' onClick={handleAcceptProposedOffer}>
                                            Kyllä
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            onClick={() => setAcceptDialog({ show: false, id: undefined })}>
                                            Peruuta
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={declineDialog.show}
                                    onClose={() => setDeclineDialog({ show: false, id: undefined, reason: '' })}>
                                    <DialogContent>
                                        <DialogContentText>
                                            Syy kuormituksen hylkäämiselle. Voit jättää tämän tyhjäksi halutessasi.
                                        </DialogContentText>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            value={declineDialog.reason}
                                            onChange={(e) =>
                                                setDeclineDialog({ ...declineDialog, reason: e.target.value })
                                            }
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant='contained' color='primary' onClick={handleRejectProposedOffer}>
                                            Hylkää
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            onClick={() =>
                                                setDeclineDialog({ show: false, id: undefined, reason: '' })
                                            }>
                                            Peruuta
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </List>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={9}>
                            <Typography variant='h2' component='h1' gutterBottom>
                                Hyväksytyt
                            </Typography>
                            <List className={classes.listPaper}>
                                {acceptedOffers[0] &&
                                    acceptedOffers[0].length > 0 &&
                                    Object.keys(acceptedOffers)
                                        .map((p) => acceptedOffers[p])
                                        .flat()
                                        .map((offer: OfferData) => (
                                            <ListItem key={offer.id} className={classes.offer}>
                                                <Grid container>
                                                    <Grid item xs={1} className={classes.offerFirstRow}>
                                                        {offer.number ? '#' + offer.number : ''}
                                                    </Grid>
                                                    <Grid item xs={4} className={classes.offerFirstRow}>
                                                        {offer.customer ? offer.customer.name : '-'}
                                                    </Grid>
                                                    <Grid item xs={5} className={classes.offerFirstRow}>
                                                        <span className={classes.dateLabel}>Jätetty:</span>
                                                        {offer.submissionTime
                                                            ? new Date(offer.submissionTime).toLocaleString()
                                                            : '-'}
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            className={classes.decisionButton}
                                                            onClick={() =>
                                                                setCompleteDialog({ show: true, id: offer.id })
                                                            }>
                                                            Valmis
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={1} className={classes.infoButtonWrapper}>
                                                        <IconButton
                                                            className={classes.infoButton}
                                                            edge='end'
                                                            aria-label='info'
                                                            onClick={() => setInfoModal(offer)}
                                                            size='large'>
                                                            <Icon>info</Icon>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={1} />
                                                    <Grid item xs={4}>
                                                        {offer.reference || '-'}
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <span className={classes.dateLabel}>Halutaan viimeistään:</span>
                                                        {offer.fast || false
                                                            ? 'PIKA'
                                                            : offer.deadlineWish
                                                            ? new Date(offer.deadlineWish).toLocaleDateString()
                                                            : '-'}
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button
                                                            variant='contained'
                                                            color='secondary'
                                                            className={classes.decisionButton}
                                                            onClick={() =>
                                                                setDeclineDialog({
                                                                    show: true,
                                                                    id: offer.id,
                                                                    reason: '',
                                                                })
                                                            }>
                                                            Peruuta
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        ))}
                                {(acceptedPage + 1) * 10 < acceptedSize && (
                                    <Grid container justifyContent='center'>
                                        <Grid item>
                                            <Box my={0.25}>
                                                <Button
                                                    variant='text'
                                                    color='secondary'
                                                    className={classes.showMore}
                                                    onClick={() => handleShowMore('accepted')}>
                                                    Näytä lisää
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                                {(!acceptedOffers[0] || acceptedOffers[0]?.length === 0) && (
                                    <Box p={1}>Ei hyväksyttyjä tarjouspyyntöjä</Box>
                                )}
                                <Dialog
                                    open={completeDialog.show}
                                    onClose={() => setCompleteDialog({ show: false, id: undefined })}>
                                    <DialogContent>
                                        <DialogContentText>
                                            Oletko varma että haluat merkitä tarjouspyynnön käsitellyksi?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={handleCompleteProposedOffer}>
                                            Kyllä
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            onClick={() => setCompleteDialog({ show: false, id: undefined })}>
                                            Peruuta
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </List>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                            <Typography variant='h2' component='h1' gutterBottom>
                                Valmiit
                            </Typography>
                            <List className={classes.listPaper}>
                                {doneOffers[donePage] &&
                                    doneOffers[donePage].length > 0 &&
                                    doneOffers[donePage].map((offer: OfferData) => (
                                        <ListItem
                                            key={offer.id}
                                            className={`${classes.offer} ${
                                                new Date(offer.deadlineWish || 0) < new Date(offer.completionTime || 0)
                                                    ? 'late'
                                                    : 'on-time'
                                            }`}>
                                            <Grid container>
                                                <Grid item xs={1} className={classes.offerFirstRow}>
                                                    {offer.number ? '#' + offer.number : '-'}
                                                </Grid>
                                                <Grid item xs={4} className={classes.offerFirstRow}>
                                                    {offer.customer ? offer.customer.name : '-'}
                                                </Grid>
                                                <Grid item xs={6} className={classes.offerFirstRow}>
                                                    <span className={classes.dateLabel}>Jätetty:</span>
                                                    {offer.submissionTime
                                                        ? new Date(offer.submissionTime).toLocaleString()
                                                        : '-'}
                                                </Grid>
                                                <Grid item xs={1} className={classes.infoButtonWrapper}>
                                                    <IconButton
                                                        className={classes.infoButton}
                                                        edge='end'
                                                        aria-label='info'
                                                        onClick={() => setInfoModal(offer)}
                                                        size='large'>
                                                        <Icon>info</Icon>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={1} />
                                                <Grid item xs={4}>
                                                    {offer.reference || '-'}
                                                </Grid>
                                                <Grid item xs={6} className={classes.offerFirstRow}>
                                                    <span className={`${classes.dateLabel}`}>Lähetetty:</span>
                                                    {offer.completionTime
                                                        ? new Date(offer.completionTime).toLocaleString()
                                                        : '-'}
                                                </Grid>
                                                <Grid item xs={1} />
                                                <Grid item xs={5} />
                                                <Grid item xs={5}>
                                                    <span className={classes.dateLabel}>Halutaan viimeistään:</span>
                                                    {offer.fast || false
                                                        ? 'PIKA'
                                                        : offer.deadlineWish
                                                        ? new Date(offer.deadlineWish).toLocaleDateString()
                                                        : '-'}
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    ))}
                                {(!doneOffers[donePage] || doneOffers[donePage]?.length === 0) && (
                                    <Box p={1}>Ei hyväksyttyjä tarjouspyyntöjä</Box>
                                )}
                            </List>
                            <Box mt={1}>
                                <Grid container justifyContent='center'>
                                    <Grid item>
                                        <Pagination
                                            showFirstButton
                                            showLastButton
                                            variant='outlined'
                                            shape='rounded'
                                            siblingCount={1}
                                            page={donePage + 1}
                                            count={Math.max(Math.ceil(doneSize / 10), 1)}
                                            onChange={(e, v) => handlePagination('done', v - 1)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <OfferInfoModal open={Boolean(infoModal)} offer={infoModal} handleClose={() => setInfoModal(null)} />
        </Root>
    );
};

export default MyOffers;
