import { AjaxResponse, AjaxError } from 'rxjs/ajax';

import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    GetUserAction,
    GetUserSuccessAction,
    GetUserErrorAction,
    User,
} from './types';

export const getUser = (): GetUserAction => ({
    type: GET_USER,
});

export const getUserSuccess = (res: AjaxResponse<User>): GetUserSuccessAction => ({
    type: GET_USER_SUCCESS,
    payload: {
        id: res.response.id,
        name: res.response.name,
        email: res.response.email,
        role: res.response.role,
    },
});

export const getUserError = (error: AjaxError): GetUserErrorAction => ({
    type: GET_USER_ERROR,
    error,
});
