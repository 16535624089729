import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import {
    GET_STATISTICS,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_ERROR,
    GetStatisticsAction,
    GetStatisticsSuccessAction,
    GetStatisticsErrorAction,
    Statistics,
} from './types';

export const getStatistics = (): GetStatisticsAction => ({
    type: GET_STATISTICS,
});

export const getStatisticsSuccess = (res: AjaxResponse<Statistics>): GetStatisticsSuccessAction => ({
    type: GET_STATISTICS_SUCCESS,
    payload: {
        statistics: res.response,
    },
});

export const getStatisticsError = (error: AjaxError): GetStatisticsErrorAction => ({
    type: GET_STATISTICS_ERROR,
    error,
});
