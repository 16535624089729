import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Container,
    Grid,
    Box,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Icon,
    Tooltip,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useSelector, useDispatch } from 'react-redux';

import OfferInfoModal from '../../components/offer-info-modal';
import { RootState } from '../../redux';
import { OfferData } from '../../redux/offer/types';
import {
    getUnassignedOffers,
    getStaff,
    assignOffer,
    getAssignedOffers,
    changeOffersPage,
} from '../../redux/assign/actions';

import { User } from '../../redux/assign/types';

const PREFIX = 'Assign';

const classes = {
    listPaper: `${PREFIX}-listPaper`,
    offer: `${PREFIX}-offer`,
    offerFirstRow: `${PREFIX}-offerFirstRow`,
    dateLabel: `${PREFIX}-dateLabel`,
    infoButton: `${PREFIX}-infoButton`,
    declined: `${PREFIX}-declined`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.listPaper}`]: {
        boxShadow: theme.shadows[1],
    },

    [`& .${classes.offer}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
        },
        '&.offered, &.accepted': {
            borderRight: `5px solid ${theme.palette.warning.main}`,
        },
        '&.accepted': {
            borderRightColor: theme.palette.info.main,
        },
    },

    [`& .${classes.offerFirstRow}`]: {
        marginBottom: '.5rem',
    },

    [`& .${classes.dateLabel}`]: {
        display: 'inline-block',
        width: '200px',
    },

    [`& .${classes.infoButton}`]: {
        padding: 0,
        transition: `opacity ${theme.transitions.duration.shortest}ms`,
        '&:hover': {
            opacity: 0.8,
        },
    },

    [`& .${classes.declined}`]: {
        color: theme.palette.error.main,
    },
}));

const Assign: FC = () => {
    const dispatch = useDispatch();

    const { offers, offersPage, offersSize, assigned, assignedTotalCounts, staff } = useSelector(
        (state: RootState) => state.assign,
    );
    const [selectedOffer, setSelectedOffer] = useState<OfferData | null>(null);
    useEffect(() => {
        if (!offers) {
            dispatch(getUnassignedOffers());
        }
    }, [offers, dispatch]);

    const [selectedStaff, setSelectedStaff] = useState<User | null>(null);
    useEffect(() => {
        dispatch(getStaff());
        document.title = 'Tarjouspyyntöportaali - kuormitus';
    }, []);

    const [infoModal, setInfoModal] = useState<OfferData | null>(null);

    const handleAssign = () => {
        if (selectedOffer && selectedOffer.id && selectedStaff && selectedStaff.id) {
            dispatch(assignOffer(selectedOffer.id, selectedStaff.id));
            setSelectedOffer(null);
            setSelectedStaff(null);
        }
    };

    const handleSelectStaff = (person: User) => {
        setSelectedStaff(person);
        dispatch(getAssignedOffers(person.id, 0));
    };

    const handlePagination = (page: number) => {
        dispatch(changeOffersPage(page));
        dispatch(getUnassignedOffers());
    };

    return (
        <Root>
            <Container maxWidth='xl'>
                <Box mt={2} mb={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <Typography variant='h2' component='h1' gutterBottom>
                                Kuormittamattomat
                            </Typography>
                            <List className={classes.listPaper}>
                                {offers &&
                                    offers.length > 0 &&
                                    offers.map((ua: OfferData) => (
                                        <ListItem
                                            key={ua.id}
                                            className={classes.offer}
                                            selected={selectedOffer ? ua.id === selectedOffer.id : false}
                                            button
                                            onClick={() => setSelectedOffer(ua)}>
                                            <Grid container>
                                                <Grid item xs={1} className={classes.offerFirstRow}>
                                                    {ua.number ? '#' + ua.number : ''}
                                                </Grid>
                                                <Grid item xs={4} className={classes.offerFirstRow}>
                                                    {ua.customer ? ua.customer.name : '-'}
                                                </Grid>
                                                <Grid item xs={6} className={classes.offerFirstRow}>
                                                    <span className={classes.dateLabel}>Jätetty:</span>
                                                    {ua.submissionTime
                                                        ? new Date(ua.submissionTime).toLocaleString()
                                                        : '-'}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        className={classes.infoButton}
                                                        edge='end'
                                                        aria-label='info'
                                                        onClick={() => setInfoModal(ua)}
                                                        size='large'>
                                                        <Icon>info</Icon>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={1} />
                                                <Grid item xs={4}>
                                                    {ua.reference || '-'}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <span className={classes.dateLabel}>Halutaan viimeistään:</span>
                                                    {ua.fast || false
                                                        ? 'PIKA'
                                                        : ua.deadlineWish
                                                        ? new Date(ua.deadlineWish).toLocaleDateString()
                                                        : '-'}
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    ))}
                                {(!offers || offers.length === 0) && (
                                    <Box p={1}>Ei kuormittamattomia tarjouspyyntöjä</Box>
                                )}
                            </List>
                            <Box mt={1}>
                                <Grid container justifyContent='center'>
                                    <Grid item>
                                        <Pagination
                                            showFirstButton
                                            showLastButton
                                            variant='outlined'
                                            shape='rounded'
                                            siblingCount={1}
                                            page={offersPage + 1}
                                            count={Math.max(Math.ceil(offersSize / 10), 1)}
                                            onChange={(e, v) => handlePagination(v - 1)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography variant='h2' component='h1' gutterBottom>
                                Ihmiset
                            </Typography>
                            <List className={classes.listPaper}>
                                {staff &&
                                    staff.map((person: User) => (
                                        <Tooltip
                                            key={person.id}
                                            title={
                                                selectedOffer?.assignments &&
                                                selectedOffer.assignments.some((a) => a.user === person.id)
                                                    ? [...selectedOffer.assignments]
                                                          .reverse()
                                                          .find((a) => a.user === person.id)?.reason || ''
                                                    : ''
                                            }
                                            placement='top'>
                                            <ListItem
                                                selected={selectedStaff ? person.id === selectedStaff.id : false}
                                                className={`${
                                                    selectedOffer &&
                                                    selectedOffer.assignments &&
                                                    selectedOffer.assignments.some((a) => a.user === person.id)
                                                        ? classes.declined
                                                        : false
                                                }`}
                                                button
                                                onClick={() => handleSelectStaff(person)}>
                                                <ListItemText primary={person.name} />
                                            </ListItem>
                                        </Tooltip>
                                    ))}
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={1}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={!(selectedOffer && selectedStaff)}
                                    onClick={handleAssign}>
                                    Kuormita
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Typography variant='h2' component='h1' gutterBottom>
                                Henkilölle jo kuormitetut
                            </Typography>
                            <List className={classes.listPaper}>
                                {selectedStaff &&
                                    assigned[selectedStaff.id] &&
                                    assigned[selectedStaff.id][0]?.length > 0 &&
                                    assigned[selectedStaff.id][0].map((ua: OfferData) => (
                                        <ListItem
                                            key={ua.id}
                                            className={`${classes.offer} ${
                                                ua.assignments?.some((a) => a.status === 'ACCEPT')
                                                    ? 'accepted'
                                                    : 'offered'
                                            }`}>
                                            <Grid container>
                                                <Grid item xs={1} className={classes.offerFirstRow}>
                                                    {ua.number ? '#' + ua.number : ''}
                                                </Grid>
                                                <Grid item xs={4} className={classes.offerFirstRow}>
                                                    {ua.customer ? ua.customer.name : '-'}
                                                </Grid>
                                                <Grid item xs={6} className={classes.offerFirstRow}>
                                                    <span className={classes.dateLabel}>Jätetty:</span>
                                                    {ua.submissionTime
                                                        ? new Date(ua.submissionTime).toLocaleString()
                                                        : '-'}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        className={classes.infoButton}
                                                        edge='end'
                                                        aria-label='info'
                                                        onClick={() => setInfoModal(ua)}
                                                        size='large'>
                                                        <Icon>info</Icon>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={1} />
                                                <Grid item xs={4}>
                                                    {ua.reference || '-'}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <span className={classes.dateLabel}>Halutaan viimeistään:</span>
                                                    {ua.fast || false
                                                        ? 'PIKA'
                                                        : ua.deadlineWish
                                                        ? new Date(ua.deadlineWish).toLocaleDateString()
                                                        : '-'}
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    ))}
                                {selectedStaff &&
                                    assigned[selectedStaff.id] &&
                                    assignedTotalCounts[selectedStaff.id] > assigned[selectedStaff.id][0].length && (
                                        <Box p={1}>+{assignedTotalCounts[selectedStaff.id] - 10} muuta</Box>
                                    )}
                                {!selectedStaff && <Box p={1}>Henkilöä ei valittu</Box>}
                                {selectedStaff &&
                                    selectedStaff &&
                                    assigned[selectedStaff.id] &&
                                    assigned[selectedStaff.id]['0']?.length === 0 && (
                                        <Box p={1}>Henkilölle ei ole kuormitettu mitään tällä hetkellä</Box>
                                    )}
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <OfferInfoModal open={Boolean(infoModal)} offer={infoModal} handleClose={() => setInfoModal(null)} />
        </Root>
    );
};

export default Assign;
